export class CacheBusterHelper {
  public static cacheBuster: string
  public static getCacheBuster(): string {
    // console.log('getCacheBuster', this.cacheBuster)
    if (!this.cacheBuster) {
      this.cacheBuster = `${new Date().getTime()}`
      return this.cacheBuster
    }
    return this.cacheBuster
  }

  public static refreshCacheBuster(): void {
    console.log(
      'CacheBusterHelper > refreshCacheBuster - old',
      this.cacheBuster,
    )
    this.cacheBuster = `${new Date().getTime()}`
    console.log(
      'CacheBusterHelper > refreshCacheBuster - new',
      this.cacheBuster,
    )
  }
}
