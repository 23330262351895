import { takeEvery, put } from 'redux-saga/effects'
import {
  EulogiseResource,
  IInvoice,
  IInvoiceTransaction,
  InvoiceActionTypes,
} from '@eulogise/core'
import RequestHelper from '../../helpers/RequestHelper'

function* handleFetchInvoices() {
  try {
    const {
      data: { items: invoices },
    } = yield RequestHelper.findResourceRequest(EulogiseResource.INVOICE)

    // @ts-ignore
    if (invoices?.length > 0) {
      const [firstInvoice]: Array<IInvoice> = invoices
      let transactions: Array<IInvoiceTransaction> = []
      for (const transactionId of firstInvoice.transactions) {
        const {
          data: { items },
        } = yield RequestHelper.findResourceRequest(
          EulogiseResource.TRANSACTION,
          undefined,
          { id: transactionId },
        )
        transactions = transactions.concat(items)
      }
      firstInvoice.transactions = transactions
      yield put({
        type: InvoiceActionTypes.FETCH_INVOICES_SUCCESS,
        payload: { invoices },
      })
    }
  } catch (ex) {
    yield put({
      type: InvoiceActionTypes.FETCH_INVOICES_FAILED,
      payload: ex,
    })
  }
}

/* Watchers */
function* watchers() {
  yield takeEvery(InvoiceActionTypes.FETCH_INVOICES, handleFetchInvoices)
}

export const InvoiceSagas = [watchers()]
