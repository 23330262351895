import { EulogiseImageLibrarySortingBy } from '@eulogise/core'
import {
  AssetActionTypes,
  AssetType,
  IAsset,
  IAssetAction,
  IAssetState,
  IAudioAsset,
  IImageAsset,
} from '@eulogise/core'
import { AssetHelper } from '@eulogise/helpers'

const initialState: IAssetState = {
  items: [],
  audios: [],
  images: [],
  brands: [],
  isFetching: false,
  sortBy: EulogiseImageLibrarySortingBy.UPLOAD_TIME,
  isUploadingEditedImage: false,
  uploadingEditedImageIndex: -1,
  isRemovingImageBackground: false,
  removingImageBackgroundImageIndex: -1,
}

export const AssetReducer = (
  state: IAssetState = initialState,
  action: IAssetAction,
): IAssetState => {
  switch (action.type) {
    case AssetActionTypes.RESET_ASSET_STATE: {
      return initialState
    }
    case AssetActionTypes.FETCH_ASSETS_BY_CASE_ID: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case AssetActionTypes.FETCH_ASSETS_BY_CASE_ID_SUCCESS: {
      const newItems = action.payload?.items
      const newAssetType = action.payload?.assetType
      const sortBy =
        action.payload?.sortBy ?? EulogiseImageLibrarySortingBy.UPLOAD_TIME
      const customisedImagesOrderIds = action.payload?.customisedImagesOrderIds

      const audios = (
        (newAssetType === AssetType.AUDIO
          ? newItems?.filter((item: IAsset) => item.type === AssetType.AUDIO)
          : state.audios) || []
      ).sort((a, b) =>
        a.updatedAt > b.updatedAt ? -1 : 1,
      ) as Array<IAudioAsset>

      let images = (
        (newAssetType === AssetType.IMAGE
          ? newItems?.filter((item: IAsset) => item.type === AssetType.IMAGE)
          : state.images) || []
      ).sort((a, b) =>
        a.updatedAt > b.updatedAt ? -1 : 1,
      ) as Array<IImageAsset>

      if (
        sortBy === EulogiseImageLibrarySortingBy.CUSTOMISED &&
        customisedImagesOrderIds?.length > 0
      ) {
        const customisedOrderImages =
          AssetHelper.getNewImagesOrderByStoredImagesOrderIds(
            images,
            customisedImagesOrderIds,
          )
        images = customisedOrderImages
      }
      return {
        ...state,
        items: [...images, ...audios],
        images,
        audios,
        sortBy,
        isFetching: false,
      }
    }
    case AssetActionTypes.FETCH_ASSETS_BY_CASE_ID_FAILED: {
      return {
        ...state,
        isFetching: false,
      }
    }
    case AssetActionTypes.FETCH_BRANDS_BY_CLIENT_ID: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case AssetActionTypes.FETCH_BRANDS_BY_CLIENT_ID_SUCCESS: {
      const newItems = action.payload?.items
      const oldStateItems = state.items ?? ([] as IAsset[])

      const brands = newItems?.filter(
        (item: IAsset) => item.type === AssetType.BRAND,
      ) as Array<IImageAsset>

      return {
        ...state,
        items: [...oldStateItems, ...brands],
        brands,
        isFetching: false,
      }
    }
    case AssetActionTypes.FETCH_BRANDS_BY_CLIENT_ID_FAILED: {
      return {
        ...state,
        isFetching: false,
      }
    }
    case AssetActionTypes.SAVE_ASSET_SUCCESS: {
      const { image, assetType } = action.payload!
      if (assetType === AssetType.AUDIO) {
        return state
      }
      if (!image) {
        return state
      }
      // Response is to update an existing image
      if (image?.type == AssetType.IMAGE && state?.images?.length! > 0) {
        const existingImageIds: string[] = state?.images?.map((i) => i.id) ?? []
        if (existingImageIds.includes(image?.id)) {
          const updatedImages = state.images?.map((oldImage) =>
            oldImage.id === image.id ? image : oldImage,
          )
          const updatedItems = state.images?.map((oldItem) =>
            oldItem.id === image.id ? image : oldItem,
          )
          return {
            ...state,
            items: updatedImages,
            images: updatedItems,
          }
        }
      }
      return {
        ...state,
        items: [image, ...state.items!],
        images: [image, ...state.images!],
      }
    }
    case AssetActionTypes.SAVE_ASSET_SUCCESS_WITH_INSERT_INDEX: {
      const { image, assetType, insertIndex } = action.payload!
      if (assetType === AssetType.AUDIO) {
        return state
      }
      if (!image) {
        return state
      }
      const newInsertedItems = state.items?.toSpliced(insertIndex, 0, image)
      const newInsertedImage = state.images?.toSpliced(insertIndex, 0, image)
      return {
        ...state,
        items: newInsertedItems,
        images: newInsertedImage,
      }
    }
    case AssetActionTypes.REMOVE_ASSET_SUCCESS: {
      const { assetId } = action.payload!
      return {
        ...state,
        items: state.items?.filter((item: IAsset) => item.id !== assetId),
        images: state.images?.filter((item: IAsset) => item.id !== assetId),
      }
    }
    case AssetActionTypes.UPDATE_IMAGES_ORDER: {
      const { newOrderImages } = action.payload!
      return {
        ...state,
        images: newOrderImages,
      }
    }
    case AssetActionTypes.UPDATE_IMAGES_SORTED_BY: {
      const { sortBy } = action.payload!
      return {
        ...state,
        sortBy,
      }
    }
    case AssetActionTypes.UPLOAD_EDITED_IMAGE: {
      const { uploadingEditedImageIndex } = action.payload!
      return {
        ...state,
        isUploadingEditedImage: true,
        uploadingEditedImageIndex,
      }
    }
    case AssetActionTypes.UPLOAD_EDITED_IMAGE_SUCCESS: {
      return {
        ...state,
        isUploadingEditedImage: false,
        uploadingEditedImageIndex: -1,
      }
    }
    case AssetActionTypes.UPLOAD_EDITED_IMAGE_FAILED: {
      return {
        ...state,
        isUploadingEditedImage: false,
        uploadingEditedImageIndex: -1,
      }
    }
    case AssetActionTypes.REMOVE_IMAGE_BACKGROUND: {
      const { removingImageBackgroundImageIndex } = action.payload!
      return {
        ...state,
        isRemovingImageBackground: true,
        removingImageBackgroundImageIndex,
      }
    }
    case AssetActionTypes.REMOVE_IMAGE_BACKGROUND_SUCCESS: {
      return {
        ...state,
        isRemovingImageBackground: false,
        removingImageBackgroundImageIndex: -1,
      }
    }
    case AssetActionTypes.REMOVE_IMAGE_BACKGROUND_FAILED: {
      return {
        ...state,
        isRemovingImageBackground: false,
        removingImageBackgroundImageIndex: -1,
      }
    }
    default:
      return state
  }
}

export const AssetInitialState = initialState
