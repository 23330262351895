import React from 'react'
import ReactDOM from 'react-dom'
import Immutable from 'immutable'
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  DefaultDraftBlockRenderMap,
  Editor,
  EditorState,
  RawDraftContentState,
} from 'draft-js'
import Mustache from 'mustache'
import {
  AlignmentType,
  AU_BOOKLET_CONTENT_HEIGHT,
  AU_BOOKLET_CONTENT_WIDTH,
  AU_BOOKLET_FULL_WIDTH_WIDTH,
  BLEED,
  BLEED_IN_MM,
  BleedPageMode,
  CARD_PRODUCT_DEFAULT_COMMON_DATA,
  CARD_PRODUCT_DEFAULT_THEME_DATA,
  CARD_PRODUCTS_OVERLAYS,
  CardProductBorderPageType,
  CardProductBorderType,
  CardProductContentItemType,
  CardProductPageColMode,
  CardProductPageMode,
  CardProductPageOrientation,
  CardProductPageSize,
  CardProductViewDisplayMode,
  DEFAULT_DUMMY_IMAGE_FILESTACK_HANDLE,
  DEFAULT_ORIGINAL_FRAME_SIZE,
  DYNAMIC_THEME_FONT_SIZE_ADAPTION_THRESHOLD,
  EulogiseCardProducts,
  EulogiseExportProductName,
  EulogisePage,
  EulogiseProduct,
  EulogiseProductDownloadProductName,
  EulogiseProductName,
  EulogiseProductPageCursors,
  EulogiseProductShortName,
  EulogiseRegion,
  EulogiseResource,
  EulogiseResourceName,
  EulogiseUserRole,
  getCardProductBorderStyle,
  GetImageObject,
  IAllActiveCardProducts,
  IBorderSettingsModalFormFields,
  ICardPopulatedTextData,
  ICardPopulatedTextDataPrimaryImage,
  ICardProductBackground,
  ICardProductColumnRowData,
  ICardProductContent,
  ICardProductData,
  ICardProductFrameColumnsItem,
  ICardProductFrameContentItem,
  ICardProductFrameItem,
  ICardProductFrameParentItem,
  ICardProductFrameRow,
  ICardProductFrameRowData,
  ICardProductFrameRowsItem,
  ICardProductImageRow,
  ICardProductImageRowData,
  ICardProductImageType,
  ICardProductNewPageStyles,
  ICardProductOverlayUpdateOptions,
  ICardProductPage,
  ICardProductPageStyle,
  ICardProductRow,
  ICardProductRowData,
  ICardProductSingleBorder,
  ICardProductState,
  ICardProductTextRow,
  ICardProductTextRowData,
  ICardProductTheme,
  ICaseData,
  IEulogiseState,
  IImageAsset,
  IImageAssetContent,
  IImageSize,
  IPageContentWidthAndHeight,
  IResourceRowContent,
  IRowStyle,
  ISlideshowTheme,
  ITheme,
  MarginType,
  MAX_DUMMY_IMAGE_DIMENSION,
  MemorialVisualStatus,
  MemorialVisualStatusLevelOrder,
  MM_TO_PAGE_SIZE_SCALE,
  ModalId,
  PAGE_SIZES,
  PRODUCT_THUMBNAIL_SIZE,
  ResourceFileStatus,
  US_BOOKLET_CONTENT_HEIGHT,
  US_BOOKLET_CONTENT_WIDTH,
  US_BOOKLET_FULL_WIDTH_WIDTH,
  VIEW_PORT_IN_MM,
} from '@eulogise/core'
import {
  CARD_PRODUCT_NEW_PAGE_FRAMES_ROWS,
  CARD_PRODUCT_NEW_PAGE_IMAGE_ROWS,
  CARD_PRODUCT_NEW_PAGE_TEXT_ROWS,
} from './cardProduct.constants'
import { ImageHelper } from './ImageHelper'
import { EulogiseClientConfig } from '@eulogise/client-core'
import { UtilHelper } from './UtilHelper'
import { CardProductFrameHelper } from './CardProductFrameHelper'
import { ThemeHelper } from './ThemeHelper'
import { FontHelper } from './FontHelper'
import { DateTimeHelper } from './DateTimeHelper'
import { ICardProductFrameImageContent } from '@eulogise/core/dist/types/EulogiseCardProductFrame.types'
import { CacheBusterHelper } from './CacheBusterHelper'

const THANK_YOU_CARD_PAGE_SIZE: { [key: number]: string } = {
  1: 'THANKYOUCARD',
  2: 'THANKYOUCARD_2_COLS',
}
const TV_WELCOME_SCREEN_PAGE_SIZE: { [key: number]: string } = {
  1: 'TV_WELCOME_SCREEN',
  2: 'TV_WELCOME_SCREEN_2_COLS',
}

const getDefaultThemeDataByRegion = (
  region: EulogiseRegion,
): {
  [key: string]: ICardPopulatedTextData & {
    bookmarkPrimaryImage?: ICardPopulatedTextDataPrimaryImage
    thankyouCardPrimaryImage?: ICardPopulatedTextDataPrimaryImage
    tvWelcomeScreenPrimaryImage?: ICardPopulatedTextDataPrimaryImage
  }
} => {
  return {
    aura: {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Lisa Colby',
      primaryImage: {
        height: 232,
        width: 232,
      },
      bookmarkPrimaryImage: {
        width: 129,
        height: 129,
      },
      tvWelcomeScreenPrimaryImage: {
        height: 276,
        width: 276,
      },
    },
    grandeur: {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Diego Garcia',
      primaryImage: {
        lockAspectRatio: true,

        height:
          region === EulogiseRegion.USA
            ? US_BOOKLET_CONTENT_WIDTH
            : AU_BOOKLET_CONTENT_WIDTH,
        width:
          region === EulogiseRegion.USA
            ? US_BOOKLET_CONTENT_HEIGHT
            : AU_BOOKLET_CONTENT_HEIGHT,
        borderRadius: '200px',
      },
      bookmarkPrimaryImage: {
        lockAspectRatio: true,

        width: 132,
        height: 132,
        borderRadius: '100px',
      },
      thankyouCardPrimaryImage: {
        lockAspectRatio: true,

        height: 172,
        width: 172,
        borderRadius: '100px',
      },
      tvWelcomeScreenPrimaryImage: {
        lockAspectRatio: true,
        height: 280,
        width: 280,
      },
    },
    linen: {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Kimberly Davenport',
      primaryImage: {
        height: 293,
        width: 293,
      },
      bookmarkPrimaryImage: {
        width: 132,
        height: 160,
      },
      thankyouCardPrimaryImage: {
        height: 172,
        width: 172,
      },
      tvWelcomeScreenPrimaryImage: {
        height: 273,
        width: 220,
      },
    },
    reflection: {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Eva Langmoore',
      primaryImage: {
        height: 334,
        width: 334,
      },
      bookmarkPrimaryImage: {
        width: 132,
        height: 245,
      },
      tvWelcomeScreenPrimaryImage: {
        height: 287,
        width: 262,
      },
    },
    grace: {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Angela Ambrose',
      primaryImage: {
        height: region === EulogiseRegion.USA ? US_BOOKLET_CONTENT_WIDTH : 354,
        width: region === EulogiseRegion.USA ? US_BOOKLET_CONTENT_HEIGHT : 354,
      },
      bookmarkPrimaryImage: {
        width: 152,
        height: 275,
      },
      tvWelcomeScreenPrimaryImage: {
        height: 270,
        width: 270,
      },
    },
    classic: {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Ross Adley',
      primaryImage: {
        height: DEFAULT_ORIGINAL_FRAME_SIZE,
        width: (DEFAULT_ORIGINAL_FRAME_SIZE * 2) / 3,
        borderRadius: '90%',
      },
      bookmarkPrimaryImage: {
        width: (DEFAULT_ORIGINAL_FRAME_SIZE * 2) / 3,
        height: DEFAULT_ORIGINAL_FRAME_SIZE,
        borderRadius: '90%',
      },
      tvWelcomeScreenPrimaryImage: {
        height: 288,
        width: 223,
      },
    },
    'gold-roses': {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Sarah J. McFarland',
      primaryImage: {
        height: 213,
        width: 213,
        lockAspectRatio: true,
        borderRadius: '100rem',
      },
      bookmarkPrimaryImage: {
        borderRadius: '100rem',

        lockAspectRatio: true,
        width: 136,
        height: 136,
      },
      tvWelcomeScreenPrimaryImage: {
        lockAspectRatio: true,
        width: 227,
        height: 227,
      },
    },
    'watercolor-sailing': {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Sarah J. McFarland',
      primaryImage: {
        height: 210,
        width: 210,
      },
      thankyouCardPrimaryImage: {
        height: 179,
        width: 179,
        borderRadius: '.5rem',
      },
      bookmarkPrimaryImage: {
        borderRadius: '90%',

        width: 140,
        height: 210,
      },
      tvWelcomeScreenPrimaryImage: {
        height: 210,
        width: 210,
      },
    },
    'pastel-blue-roses': {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Pastel Blue Roses',
      primaryImage: {
        height: 211,
        width:
          region === EulogiseRegion.USA
            ? US_BOOKLET_CONTENT_HEIGHT
            : AU_BOOKLET_CONTENT_HEIGHT,
      },
      bookmarkPrimaryImage: {
        borderRadius: '50% 50% 0 0',

        width: 151,
        height: 207,
      },
      tvWelcomeScreenPrimaryImage: {
        borderRadius: '50% 50% 0 0',

        height: 248,
        width: 169,
      },
    },
    'pink-pastel': {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Jane Louise Jones',
      primaryImage: {
        height: 187,
        width:
          region === EulogiseRegion.USA
            ? US_BOOKLET_CONTENT_HEIGHT
            : AU_BOOKLET_CONTENT_HEIGHT,
      },
      thankyouCardPrimaryImage: {
        width: 174,
        height: 110,
      },
      bookmarkPrimaryImage: {
        width: 132,
        height: 160,
      },
      tvWelcomeScreenPrimaryImage: {
        height: 180,
        width: 290,
      },
    },
    'fall-flowers': {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Fall Flowers',
      primaryImage: {
        borderRadius: '200px',
        lockAspectRatio: true,
        height:
          region === EulogiseRegion.USA
            ? US_BOOKLET_CONTENT_WIDTH
            : AU_BOOKLET_CONTENT_WIDTH,
        width:
          region === EulogiseRegion.USA
            ? US_BOOKLET_CONTENT_HEIGHT
            : AU_BOOKLET_CONTENT_HEIGHT,
      },
      thankyouCardPrimaryImage: {
        height: 172,
        width: 172,
        borderRadius: '100px',
      },
      bookmarkPrimaryImage: {
        width: 129,
        height: 129,
      },
      tvWelcomeScreenPrimaryImage: {
        height: 240,
        width: 240,
        lockAspectRatio: true,
      },
    },
    'minimal-arch': {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Minimal Arch',
      primaryImage: {
        borderRadius: '50% 50% 0 0',
        height: 295,
        width: 284,
      },
      thankyouCardPrimaryImage: {
        borderRadius: '50% 50% 0 0',
        width: 180,
        height: 180,
        lockAspectRatio: true,
      },
      bookmarkPrimaryImage: {
        borderRadius: '50% 50% 0 0',
        lockAspectRatio: true,
        width: 132,
        height: 132,
      },
      tvWelcomeScreenPrimaryImage: {
        borderRadius: '50% 50% 0 0',
        height: 280,
        width: 280,
      },
    },
    'minimal-collage': {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Jane M. Jones',
      primaryImage: {
        height: region === EulogiseRegion.USA ? US_BOOKLET_CONTENT_WIDTH : 340,
        width: region === EulogiseRegion.USA ? US_BOOKLET_CONTENT_HEIGHT : 340,
      },
      bookmarkPrimaryImage: {
        width: 132,
        height: 245,
      },
      // no thankyouCardPrimaryImage need as no primary image in the theme
      // no tvWelcomeScreenPrimaryImage need as no primary image in the theme
    },
    'modern-minimal': {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Modern Minimal',
      primaryImage: {
        height: 272,
        width: 253,
      },
      thankyouCardPrimaryImage: {
        width: 180,
        height: 219,
      },
      bookmarkPrimaryImage: {
        width: 128,
        height: 145,
      },
      tvWelcomeScreenPrimaryImage: {
        lockAspectRatio: true,
        borderRadius: '200px',
        height: 280,
        width: 280,
      },
    },
    'full-width': {
      ...CARD_PRODUCT_DEFAULT_COMMON_DATA,
      deceasedName: 'Full Width',
      primaryImage: {
        height: 334,
        width:
          region === EulogiseRegion.USA
            ? US_BOOKLET_FULL_WIDTH_WIDTH
            : AU_BOOKLET_FULL_WIDTH_WIDTH,
        isFullWidth: true,
      },
      thankyouCardPrimaryImage: {
        width: 213,
        height: 253,
        isFullWidth: true,
      },
      bookmarkPrimaryImage: {
        width: 160,
        height: 150,
        isFullWidth: true,
      },
      tvWelcomeScreenPrimaryImage: {
        isFullWidth: true,
        height: 322,
        width: 323,
      },
    },
  }
}

export type ICardProductFramePayload = {
  frameContentItemId: string
  pageIndex: number
  rowId: string
  image: IImageAssetContent
}

export type IDummyBackgroundPagesParams = {
  productTheme: ICardProductTheme
  backgroundPaths?: Array<string>
}

type CreatePrimaryImageTemplateProps = {
  defaultPrimaryImage: ICardPopulatedTextDataPrimaryImage
  primaryImage: ICardPopulatedTextDataPrimaryImage
  isCurrentImage?: boolean
  isPrimaryImage?: boolean
  defaultThemeLayoutColumns?: number
  product: EulogiseProduct
  region?: EulogiseRegion
}

export class CardProductHelper {
  private static addCacheBusterToString(url: string) {
    // Check if the URL already has query parameters
    const separator = url.includes('?') ? '&' : '?'

    // if cacheBuster query existing, replace it
    if (url.includes('cacheBuster')) {
      return url.replace(
        /cacheBuster=\d+/,
        `cacheBuster=${CacheBusterHelper.getCacheBuster()}`,
      )
    }
    // Append the cacheBuster query parameter
    return `${url}${separator}cacheBuster=${CacheBusterHelper.getCacheBuster()}`
  }

  public static addCacheBusterToCssBackground(
    backgroundCss: string,
  ): string | undefined {
    const urlPattern = /url\(['"]?(.+?)['"]?\)/g
    const matchedUrls: Array<string> | null = backgroundCss.match(urlPattern)
    if (!matchedUrls || matchedUrls.length === 0) {
      return backgroundCss
    }
    let newBackgroundCss = backgroundCss
    matchedUrls.forEach((text) => {
      const matchedUrl = text.replace(urlPattern, '$1')

      newBackgroundCss = newBackgroundCss.replace(
        matchedUrl,
        this.addCacheBusterToString(matchedUrl),
      )
    })
    return newBackgroundCss
  }

  public static async preCardProductSaveUpdateFrameItem(
    frameItem: ICardProductFrameItem,
  ): Promise<ICardProductFrameItem> {
    switch (frameItem.type) {
      case 'rows':
      case 'columns': {
        let newItems = []
        for (const item of frameItem.items) {
          newItems.push(await this.preCardProductSaveUpdateFrameItem(item))
        }
        return {
          ...frameItem,
          items: newItems,
        }
      }
      case 'content': {
        if (frameItem.content?.type === 'image') {
          const frameImageContent =
            frameItem.content as ICardProductFrameImageContent
          // only perform calculation if the frameImageContent does not have renderImageWidth, renderImageHeight, transformX, transformY
          if (
            !frameImageContent?.renderImageWidth ||
            !frameImageContent?.renderImageHeight ||
            !frameImageContent?.transformX ||
            !frameImageContent.transformY
          ) {
            const imageUrl = ImageHelper.getImageUrl(
              frameItem.content as GetImageObject,
            )
            // cannot calculate without container width and height
            if (!imageUrl) {
              return frameItem
            }

            // cannot calculate without container width and height
            if (!frameItem.width || !frameItem.height) {
              return frameItem
            }
            const containerSize = {
              width: frameItem.width,
              height: frameItem.height,
            }
            const { renderWidth, renderHeight, transformY, transformX } =
              await CardProductFrameHelper.calculateDefaultFrameImageSizeAndPosition(
                {
                  imageUrl,
                  // @ts-ignore
                  containerSize,
                  isBorderShowed: false,
                },
              )
            return {
              ...frameItem,
              content: {
                ...frameImageContent,
                renderImageWidth: renderWidth,
                renderImageHeight: renderHeight,
                transformX,
                transformY,
              },
            }
          }
        }
        return frameItem
      }
      default:
        throw new Error(
          `preCardProductSaveUpdateFrameItem: ${JSON.stringify(
            frameItem,
          )} is not supported`,
        )
    }
  }

  public static async preCardProductSaveUpdateFrame(
    frameData: ICardProductFrameRowData,
  ): Promise<ICardProductFrameRowData> {
    return {
      ...frameData,
      content: await this.preCardProductSaveUpdateFrameItem(frameData.content),
    }
  }

  public static async preCardProductSaveUpdateRow(
    row: ICardProductRow,
  ): Promise<ICardProductRow> {
    if (row.type === CardProductContentItemType.FRAME) {
      return {
        ...row,
        data: await this.preCardProductSaveUpdateFrame(row.data),
      }
    }
    return row
  }

  public static async preCardProductSaveUpdatePage(
    page: ICardProductPage,
  ): Promise<ICardProductPage> {
    const rows = page.rows
    let newRows = []
    for (const row of rows) {
      newRows.push(await this.preCardProductSaveUpdateRow(row))
    }
    return {
      ...page,
      rows: newRows,
    }
  }

  public static async preCardProductSaveUpdate(
    cardProduct: ICardProductContent,
  ): Promise<ICardProductContent> {
    const pages = cardProduct.pages
    const newPages = []
    for (const page of pages) {
      newPages.push(await this.preCardProductSaveUpdatePage(page))
    }
    return {
      ...cardProduct,
      pages: newPages,
    }
  }

  public static isAllowAutoRepopulatePrimaryImage({
    activeCase,
    cardProduct,
  }: {
    activeCase?: ICaseData
    cardProduct?: ICardProductData
  }): boolean {
    if (!activeCase || !cardProduct) {
      return false
    }
    // ONLY perform the following if the product status is THEME_SELECTED
    if (cardProduct.status !== MemorialVisualStatus.THEME_SELECTED) {
      return false
    }
    // ONLY perform the following if the product status is THEME_SELECTED
    // check if the primary image is different from the primary image in the case
    // should replace primaryImage
    const primaryImageHandle = activeCase?.deceased?.primaryImage
    if (primaryImageHandle) {
      const primaryImageSrc = ImageHelper.getImageUrl(primaryImageHandle)
      const existingPrimaryImageSrc = CardProductHelper.getPrimaryImageSrc(
        cardProduct.content,
      )
      if (primaryImageSrc !== existingPrimaryImageSrc) {
        return true
      }
    }
    return false
  }

  public static getPrimaryImageSrc(
    cardProduct: ICardProductContent,
  ): string | undefined {
    const pages = cardProduct.pages
    for (const page of pages) {
      const rows = page.rows
      for (const row of rows) {
        if (this.isPrimaryImageRow(row)) {
          const primaryImageRow = row as ICardProductFrameRow
          const firstContentItem = CardProductFrameHelper.getFirstContentItem(
            primaryImageRow.data.content,
          )
          if (!firstContentItem) {
            console.log('firstContentItem does not exist', firstContentItem)
            return
          }
          const content =
            firstContentItem.content as ICardProductFrameImageContent
          return ImageHelper.getImageUrl(content)
        }
      }
    }
    return
  }

  public static isProcessing(fileStatus: ResourceFileStatus) {
    return fileStatus === ResourceFileStatus.PROCESSING
  }

  public static createDummyBackgroundPages = ({
    productTheme,
    backgroundPaths,
  }: IDummyBackgroundPagesParams): Array<ICardProductPage> => {
    return (productTheme.defaultContent as Array<ICardProductPage>).map(
      (p, index) => {
        if (!backgroundPaths) {
          return p
        }
        return {
          ...p,
          background: {
            ...p.background,
            image: {
              url: `${EulogiseClientConfig.AWS_S3_URL}/${backgroundPaths[index]}`,
              //              filepath: backgroundPaths[index],
            },
          },
        }
      },
    )
  }

  public static createDummyBookletData = ({
    productTheme,
    backgroundPaths,
  }: IDummyBackgroundPagesParams): ICardProductData => {
    return {
      content: {
        pageMargins: CardProductHelper.getDefaultAUPageMargins(
          EulogiseProduct.BOOKLET,
        )!,
        pageSize: CardProductPageSize.A5,
        theme: productTheme.id!,
        pageOrientation: CardProductPageOrientation.PORTRAIT,
        pages: this.createDummyBackgroundPages({
          productTheme,
          backgroundPaths,
        }),
      },
      updatedAt: '2023-01-10T07:48:51.863Z',
      status: MemorialVisualStatus.THEME_SELECTED,
      createdAt: '2023-01-06T05:10:56.451Z',
      id: 'dummy-id',
      case: 'dummy-case',
    }
  }

  public static createDummyBookmarkData = ({
    productTheme,
    backgroundPaths,
  }: IDummyBackgroundPagesParams): ICardProductData => {
    return {
      content: {
        pageMargins: CardProductHelper.getDefaultAUPageMargins(
          EulogiseProduct.BOOKMARK,
        )!,
        pageSize: CardProductPageSize.BOOKMARK,
        theme: productTheme.id!,
        pageOrientation: CardProductPageOrientation.PORTRAIT,
        pages: this.createDummyBackgroundPages({
          productTheme,
          backgroundPaths,
        }),
      },
      updatedAt: '2023-01-10T07:48:51.863Z',
      status: MemorialVisualStatus.THEME_SELECTED,
      createdAt: '2023-01-06T05:10:56.451Z',
      id: 'dummy-id',
      case: 'dummy-case-id',
    }
  }

  public static createDummyTvWelcomeScreenCardData = ({
    productTheme,
    backgroundPaths,
  }: IDummyBackgroundPagesParams): ICardProductData => {
    return {
      content: {
        pageMargins: CardProductHelper.getDefaultAUPageMargins(
          EulogiseProduct.TV_WELCOME_SCREEN,
        )!,
        pageSize:
          productTheme.defaultThemeLayoutColumns === 1
            ? CardProductPageSize.TV_WELCOME_SCREEN
            : CardProductPageSize.TV_WELCOME_SCREEN_2_COLS,
        theme: productTheme.id!,
        pageOrientation: CardProductPageOrientation.PORTRAIT,
        pages: this.createDummyBackgroundPages({
          productTheme,
          backgroundPaths,
        }),
      },
      updatedAt: '2023-01-10T07:48:51.863Z',
      status: MemorialVisualStatus.THEME_SELECTED,
      createdAt: '2023-01-06T05:10:56.451Z',
      id: '71637251-d9e4-4a1d-ab1b-e4e88c7c3f99',
      case: 'a5d79550-76b9-4357-a15f-bdd20a22734d',
    }
  }

  public static createDummyThankYouCardData = ({
    productTheme,
    backgroundPaths,
  }: IDummyBackgroundPagesParams): ICardProductData => {
    return {
      content: {
        pageMargins: CardProductHelper.getDefaultAUPageMargins(
          EulogiseProduct.THANK_YOU_CARD,
        )!,
        pageSize:
          productTheme.defaultThemeLayoutColumns === 1
            ? CardProductPageSize.THANKYOUCARD
            : CardProductPageSize.THANKYOUCARD_2_COLS,
        theme: productTheme.id!,
        pageOrientation: CardProductPageOrientation.PORTRAIT,
        pages: this.createDummyBackgroundPages({
          productTheme,
          backgroundPaths,
        }),
      },
      updatedAt: '2023-01-10T07:48:51.863Z',
      status: MemorialVisualStatus.THEME_SELECTED,
      createdAt: '2023-01-06T05:10:56.451Z',
      id: '71637251-d9e4-4a1d-ab1b-e4e88c7c3f99',
      case: 'a5d79550-76b9-4357-a15f-bdd20a22734d',
    }
  }

  public static isPrimaryImageRow(row: ICardProductRow) {
    return row.dynamicDataId === 'primaryImage'
  }

  public static getProductThumbnailScaleByPageSize(
    productPageSize: CardProductPageSize,
    padding: number = 0,
  ): number {
    const [thumbnailWidth, thumbnailHeight] = PRODUCT_THUMBNAIL_SIZE
    let newProductPageSize = productPageSize
    if (productPageSize === CardProductPageSize.TV_WELCOME_SCREEN_2_COLS) {
      newProductPageSize = CardProductPageSize.TV_WELCOME_SCREEN
    } else if (productPageSize === CardProductPageSize.THANKYOUCARD_2_COLS) {
      newProductPageSize = CardProductPageSize.THANKYOUCARD
    }
    const [productWidth, productHeight] = PAGE_SIZES[newProductPageSize]
    const horizontalScale = (thumbnailWidth - padding * 2) / productWidth
    const verticalScale = (thumbnailHeight - padding * 2) / productHeight
    return Math.min(horizontalScale, verticalScale)
  }

  public static getProductState(
    currentState: IEulogiseState,
    product: EulogiseProduct,
  ) {
    const {
      slideshows,
      sidedCards,
      booklets,
      bookmarks,
      thankYouCards,
      tvWelcomeScreens,
    } = currentState
    switch (product) {
      case EulogiseProduct.SIDED_CARD:
        return sidedCards
      case EulogiseProduct.BOOKLET:
        return booklets
      case EulogiseProduct.BOOKMARK:
        return bookmarks
      case EulogiseProduct.TV_WELCOME_SCREEN:
        return tvWelcomeScreens
      case EulogiseProduct.SLIDESHOW:
        return slideshows
      case EulogiseProduct.THANK_YOU_CARD:
        return thankYouCards
      default:
        throw new Error(
          `getProductState: product type: ${product} does not exist`,
        )
    }
  }

  public static getUpdatedRowContentDataForFrame({
    framePayload,
    frameItemData,
  }: {
    framePayload: ICardProductFramePayload
    frameItemData: ICardProductFrameItem
  }): ICardProductFrameItem {
    if ((frameItemData as ICardProductFrameParentItem).items) {
      return {
        ...frameItemData,
        items: (frameItemData as ICardProductFrameParentItem).items.map(
          (item: ICardProductFrameItem) => {
            if (item.type === 'rows' || item.type === 'columns') {
              return this.getUpdatedRowContentDataForFrame({
                framePayload,
                frameItemData: item,
              })
            }
            if (
              item.type === 'content' &&
              item.id === framePayload.frameContentItemId
            ) {
              // trigger auto resize on the CardProductFrameImageItem
              const resetProps = {
                renderImageWidth: undefined,
                renderImageHeight: undefined,
                width: undefined,
                height: undefined,
                transformX: undefined,
                transformY: undefined,
              }
              return {
                ...item,
                content: {
                  type: 'image', // add default type to image
                  ...(item as ICardProductFrameContentItem).content, // 'type' will get overwritten by existing type
                  ...framePayload.image,
                  ...resetProps,
                },
              } as ICardProductFrameContentItem
            }
            return item
          },
        ),
      } as ICardProductFrameItem
    }
    return frameItemData
  }

  public static getPagePrintScale(
    displayMode: CardProductViewDisplayMode,
    product: EulogiseProduct,
    bleed?: boolean,
  ) {
    // if it is non print mode, just return 1
    if (displayMode !== CardProductViewDisplayMode.PRINT) {
      return 1
    }
    if (bleed) {
      if (product === EulogiseProduct.THANK_YOU_CARD) {
        // 101 is a number that works for Thank you card print bleed divided by 72 dpi
        return 101 / 71.8
      }
      // 99 is a number that works for Booklet, bookmark and sided card print bleed divided by 72 dpi
      return 99 / 71.8
    }
    // 96 dpi divided by 72 dpi
    return 96 / 71.8
  }

  public static isHigherProductStatusPriority(
    newStatus: MemorialVisualStatus,
    existingStatus: MemorialVisualStatus,
  ) {
    const newStatusPriority = MemorialVisualStatusLevelOrder.indexOf(newStatus)
    const existingStatusPriority = MemorialVisualStatusLevelOrder.indexOf(
      existingStatus!,
    )
    return newStatusPriority > existingStatusPriority
  }

  public static getHigherProductStatusPriority(
    newStatus: MemorialVisualStatus,
    existingStatus: MemorialVisualStatus,
  ) {
    if (this.isHigherProductStatusPriority(newStatus, existingStatus)) {
      return newStatus
    }
    return existingStatus
  }

  private static getPageStyle({
    product,
    pageWidth,
    pageHeight,
    pageMargins,
    page,
    isBleed,
    isTwoPagesButActAsOnePage,
  }: {
    product: EulogiseProduct
    pageWidth: number
    pageHeight: number
    pageMargins: Array<number> | number
    page: ICardProductPage
    isBleed?: boolean
    isTwoPagesButActAsOnePage: boolean
  }) {
    const pm = pageMargins as Array<number>
    const pageBackgroundLayers = []
    const pageStyle: ICardProductPageStyle = {
      width: pageWidth,
      height: pageHeight,
      paddingTop: pm[1],
      paddingBottom: pm[3] || pm[1],
    }

    // Comments: As we cannot use the specific pixes of margin to define overlay size, so we decide to use the percentage to define it
    // for example: if margin we passed in is [20, 30], which means the overlay width should be 80% of original, and height should be 90% of the original height,
    // and the card product page will use `contain`, which is 100% width and 100% height of the parent container

    // Add optional page content background color
    const background = page.background
    /*
    if (background && background.overlayColor) {
      const margin = background.overlayMargin
        ? background.overlayMargin
        : [0, 0]

      pageBackgroundLayers.push({
        width: pageWidth - pageWidth * margin[0],
        height: pageHeight - pageHeight * margin[1],
        color: this.hexToRgba(
          background.overlayColor,
          background.overlayOpacity || 1,
        ),
      })
    }
*/

    // Add optional page background image
    if (background && background.image) {
      const bleed: number = isTwoPagesButActAsOnePage ? BLEED * 2 : 0

      pageBackgroundLayers.push({
        width: pageWidth + bleed,
        height: pageHeight + bleed,
        url: ImageHelper.getImageUrl(background.image),
      })
    }

    if (pageBackgroundLayers.length) {
      pageStyle.backgroundImage = pageBackgroundLayers
        .map((layer) => (layer.url ? `url('${layer.url}')` : undefined))
        .filter(Boolean)
        .join(', ')

      /*
      if (background && background.overlayColor) {
        const overlayBackgroundSize = this.getDefaultBorderAndOverlaySize(page)
        const overlayBackground = overlayBackgroundSize
          ? `${overlayBackgroundSize.width}% ${overlayBackgroundSize.height}%, contain`
          : `contain`
        const originalBackground = `contain`

        pageStyle.backgroundSize = `${overlayBackground}, ${originalBackground}`
      } else {
*/
      pageStyle.backgroundSize = `contain`
      //      }
    }

    // Add optional page background color
    if (background && background.color) {
      pageStyle.backgroundColor = background.color
    }
    return pageStyle
  }

  // input with marginX and marginY and return width and height in percentage
  public static getBorderAndOverlaySize({
    marginX,
    marginY,
  }: {
    marginX: number
    marginY: number
  }):
    | {
        width: number
        height: number
      }
    | undefined {
    if (!marginX || !marginY) {
      return
    }
    const width = 100 - marginX
    const height = 100 - marginY
    return { width, height }
  }

  public static getDefaultBorderAndOverlaySize(page?: ICardProductPage) {
    const margin = page?.background?.overlayMargin
      ? page.background.overlayMargin
      : [0, 0]

    return this.getBorderAndOverlaySize({
      marginX: margin?.[0],
      marginY: margin?.[1],
    })
  }

  public static moveContent(
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any,
  ) {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result: any = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  public static getIndexFromId(id: string): number {
    return Number(id.replace('page', ''))
  }

  public static reorder(
    list: Array<any>,
    startIndex: number,
    endIndex: number,
  ) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  public static getContentHeight(rows: Array<any>) {
    return rows.reduce((total, row) => {
      let height: number = row.data.height
      if (row.type === CardProductContentItemType.COLUMNS) {
        height = row.data.items.reduce(
          (curr: number, i: any) =>
            curr < i.data.height ? i.data.height : curr,
          0,
        )
      } else if (row.type === CardProductContentItemType.IMAGE) {
        height = row.data?.height
      } else if (row.type === CardProductContentItemType.TEXT) {
        const marginTopAndBottom = (row.data?.margin?.[0] || 0) * 2
        height = row.data?.height + marginTopAndBottom
      } else if (row.type === CardProductContentItemType.SPACE) {
        height = row.data?.height
      } else if (row.type === CardProductContentItemType.FRAME) {
        height = row.data?.height
      }
      return total + (height || 0)
    }, 0)
  }

  public static contentCanFit(
    updatedRows: Array<any>,
    { pageSize, pageMargins, pageOrientation }: any,
  ) {
    const BOTTOM_MARGIN = 20
    const maxHeight =
      Math.floor(
        PAGE_SIZES[pageSize][
          pageOrientation === CardProductPageOrientation.PORTRAIT ? 1 : 0
        ] - pageMargins[1],
      ) - BOTTOM_MARGIN
    const nextHeight = this.getContentHeight(updatedRows)
    if (nextHeight >= maxHeight) {
      console.log('please reduce theme size', maxHeight - nextHeight - 1)
    }
    return nextHeight < maxHeight
  }

  public static createRowData(
    product: EulogiseProduct,
    type: CardProductContentItemType,
    productTheme?: ICardProductTheme,
    { content = '', subType = 1, region = EulogiseRegion.AU, ...rest } = {},
  ): ICardProductRowData {
    switch (type) {
      case CardProductContentItemType.TEXT:
        return {
          content: convertToRaw(
            ContentState.createFromText(content),
          ) as IResourceRowContent,
          style: 'unstyled',
          margin: [Math.ceil(productTheme?.defaultStyle.fontSize! / 2), 0],
          height:
            ((content || '').split('\n').length || 1) *
            productTheme?.defaultStyle.fontSize!,
          width: CardProductHelper.getDefaultPageContentWidthAndHeight({
            product,
            defaultThemeLayoutColumns: productTheme?.defaultThemeLayoutColumns,
            region,
          }).width,
          alignment: AlignmentType.CENTER,
          ...rest,
        }
      case CardProductContentItemType.IMAGE:
        return {
          filename: '',
          alignment: AlignmentType.CENTER,
          ...rest,
        }
      case CardProductContentItemType.COLUMNS:
        const items: Array<any> = []

        for (let i = 0; i < subType; i++) {
          items.push({
            id: UtilHelper.generateID(8),
            type: CardProductContentItemType.IMAGE,

            // TODO: Check this after migration as it looks broken somehow
            data: this.createRowData(
              product,
              CardProductContentItemType.IMAGE,
              productTheme,
              { region },
            ),
          })
        }

        return {
          items,
          ...rest,
        }
      case CardProductContentItemType.ICON:
      case CardProductContentItemType.SPACE:
        return {
          height: EulogiseClientConfig.CARD_PRODUCT_SPACE_ITEM_MIN_HEIGHT,
          ...rest,
        }
      case CardProductContentItemType.FRAME:
        return {
          height:
            (content as unknown as ICardProductFrameItem).height ??
            DEFAULT_ORIGINAL_FRAME_SIZE,
          width:
            (content as unknown as ICardProductFrameItem).width ??
            DEFAULT_ORIGINAL_FRAME_SIZE,
          originalFrameSize: DEFAULT_ORIGINAL_FRAME_SIZE,
          content: content as unknown as ICardProductFrameItem,
        } as ICardProductFrameRowData
      default:
        return {}
    }
  }

  public static isPageFull(
    cardProduct: ICardProductData,
    rows: Array<ICardProductRow>,
  ): boolean {
    const {
      content: { pageSize, pageMargins, pageOrientation },
    } = cardProduct
    return !this.contentCanFit(rows, { pageSize, pageMargins, pageOrientation })
  }

  public static hexToRgba(hex: string, opacity: number = 1) {
    let color: any

    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      color = hex.substring(1).split('')

      if (color.length === 3) {
        color = [color[0], color[0], color[1], color[1], color[2], color[2]]
      }

      color = `0x${color.join('')}`

      return `rgba(${[
        (color >> 16) & 255,
        (color >> 8) & 255,
        color & 255,
      ].join(',')}, ${opacity})`
    }

    throw new Error('Bad Hex')
  }

  public static isCardProduct(product: EulogiseProduct): boolean {
    return [
      EulogiseProduct.BOOKLET,
      EulogiseProduct.SIDED_CARD,
      EulogiseProduct.BOOKMARK,
      EulogiseProduct.THANK_YOU_CARD,
      EulogiseProduct.TV_WELCOME_SCREEN,
    ].includes(product)
  }

  public static isAnyPageFull(cardProduct: ICardProductData): boolean {
    return cardProduct?.content.pages.some((p: ICardProductPage) =>
      this.isPageFull(cardProduct, p.rows),
    )
  }

  public static getRowHeight(row: ICardProductRow): number {
    if (row.type === CardProductContentItemType.COLUMNS) {
      return row.data.items.reduce(
        (curr: number, i: ICardProductImageRow) =>
          curr < i?.data.height! ? i.data.height : curr,
        0,
      ) as number
    }
    return row.data.height! as number
  }

  public static getProductByUserRole(
    accountRole: EulogiseUserRole,
  ): EulogiseProduct | undefined {
    // @ts-ignore
    return {
      [EulogiseUserRole.VISITOR_SLIDESHOW]: EulogiseProduct.SLIDESHOW,
      [EulogiseUserRole.VISITOR_BOOKLET]: EulogiseProduct.BOOKLET,
      [EulogiseUserRole.VISITOR_BOOKMARK]: EulogiseProduct.BOOKMARK,
      [EulogiseUserRole.VISITOR_SIDED_CARD]: EulogiseProduct.SIDED_CARD,
      [EulogiseUserRole.VISITOR_THANKYOUCARD]: EulogiseProduct.THANK_YOU_CARD,
      [EulogiseUserRole.VISITOR_TV_WELCOME_SCREEN]:
        EulogiseProduct.TV_WELCOME_SCREEN,
    }[accountRole]
  }

  public static getProductByResourceName(
    resourceName: EulogiseResourceName,
  ): EulogiseProduct | undefined {
    // @ts-ignore
    return {
      slideshow: EulogiseProduct.SLIDESHOW,
      booklet: EulogiseProduct.BOOKLET,
      bookmark: EulogiseProduct.BOOKMARK,
      sidedCard: EulogiseProduct.SIDED_CARD,
      thankyouCard: EulogiseProduct.THANK_YOU_CARD,
      tvWelcomeScreen: EulogiseProduct.TV_WELCOME_SCREEN,
    }[resourceName]
  }

  public static getPreviewModalIdByProduct(product: EulogiseProduct) {
    // @ts-ignore
    return {
      [EulogiseProduct.BOOKLET]: ModalId.CARD_PRODUCT_PREVIEW,
      [EulogiseProduct.SLIDESHOW]: ModalId.SLIDESHOW_PREVIEW,
      [EulogiseProduct.BOOKMARK]: ModalId.CARD_PRODUCT_PREVIEW,
      [EulogiseProduct.SIDED_CARD]: ModalId.CARD_PRODUCT_PREVIEW,
      [EulogiseProduct.THANK_YOU_CARD]: ModalId.CARD_PRODUCT_PREVIEW,
      [EulogiseProduct.TV_WELCOME_SCREEN]: ModalId.CARD_PRODUCT_PREVIEW,
    }[product]
  }

  public static getEditPageByProduct(product: EulogiseProduct) {
    // @ts-ignore
    return {
      [EulogiseProduct.BOOKLET]: EulogisePage.EDIT_BOOKLET,
      [EulogiseProduct.SLIDESHOW]: EulogisePage.EDIT_SLIDESHOW,
      [EulogiseProduct.BOOKMARK]: EulogisePage.EDIT_BOOKMARK,
      [EulogiseProduct.SIDED_CARD]: EulogisePage.EDIT_SIDED_CARD,
      [EulogiseProduct.THANK_YOU_CARD]: EulogisePage.EDIT_THANK_YOU_CARD,
      [EulogiseProduct.TV_WELCOME_SCREEN]: EulogisePage.EDIT_TV_WELCOME_SCREEN,
    }[product]
  }

  public static getProductIdKey(product: EulogiseProduct) {
    // @ts-ignore
    return {
      [EulogiseProduct.BOOKLET]: 'bookletId',
      [EulogiseProduct.SLIDESHOW]: 'slideshowId',
      [EulogiseProduct.BOOKMARK]: 'bookmarkId',
      [EulogiseProduct.SIDED_CARD]: 'sidedCardId',
      [EulogiseProduct.THANK_YOU_CARD]: 'thankYouCardId',
      [EulogiseProduct.TV_WELCOME_SCREEN]: 'tvWelcomeScreenId',
    }[product]
  }

  public static getShareLinkUserRole(
    product: EulogiseProduct,
  ): EulogiseUserRole {
    // @ts-ignore
    return {
      [EulogiseProduct.BOOKLET]: EulogiseUserRole.VISITOR_BOOKLET,
      [EulogiseProduct.BOOKMARK]: EulogiseUserRole.VISITOR_BOOKMARK,
      [EulogiseProduct.SIDED_CARD]: EulogiseUserRole.VISITOR_SIDED_CARD,
      [EulogiseProduct.THANK_YOU_CARD]: EulogiseUserRole.VISITOR_THANKYOUCARD,
      [EulogiseProduct.TV_WELCOME_SCREEN]:
        EulogiseUserRole.VISITOR_TV_WELCOME_SCREEN,
      [EulogiseProduct.SLIDESHOW]: EulogiseUserRole.VISITOR_SLIDESHOW,
    }[product]
  }

  public static getBorderStylesByType({
    borderType,
    editorScaledFactor,
  }: {
    borderType: CardProductBorderType
    editorScaledFactor: number
  }): Array<ICardProductSingleBorder> {
    return getCardProductBorderStyle({ borderType, editorScaledFactor })
  }

  public static hasMiddlePages(product: EulogiseProduct): boolean {
    if (product === EulogiseProduct.BOOKLET) {
      return true
    }
    return false
  }

  public static hasBackPage(product: EulogiseProduct): boolean {
    if (
      product === EulogiseProduct.BOOKLET ||
      product === EulogiseProduct.BOOKMARK ||
      product === EulogiseProduct.SIDED_CARD
    ) {
      return true
    }
    return false
  }

  public static getNoOfPageCursors(
    product: EulogiseProduct,
  ): EulogiseProductPageCursors {
    return (EulogiseProductPageCursors as any)[product]
  }

  public static getResourceByProduct(
    product: EulogiseProduct,
  ): EulogiseResource {
    // @ts-ignore
    return EulogiseResource[product]
  }

  public static getProductName({
    product,
    region,
  }: {
    product: EulogiseProduct
    region: EulogiseRegion
  }): EulogiseProductName {
    if (product === EulogiseProduct.BOOKLET && region === EulogiseRegion.USA) {
      return EulogiseProductName.BOOKLET_US
    }
    // @ts-ignore
    return EulogiseProductName[product]
  }

  public static getDownloadProductName({
    product,
    region,
  }: {
    product: EulogiseProduct
    region: EulogiseRegion
  }): EulogiseProductDownloadProductName {
    if (product === EulogiseProduct.BOOKLET && region === EulogiseRegion.USA) {
      return EulogiseProductDownloadProductName.BOOKLET_US
    }
    // @ts-ignore
    return EulogiseProductDownloadProductName[product]
  }

  public static getProductServiceCardSelector(product: EulogiseProduct) {
    switch (product) {
      case EulogiseProduct.BOOKLET: {
        return '#order-of-service-booklet'
      }
      case EulogiseProduct.BOOKMARK: {
        return '#bookmark-card'
      }
      case EulogiseProduct.SIDED_CARD: {
        return '#a5-memorial-card'
      }
      case EulogiseProduct.SLIDESHOW: {
        return '#visual-tribute-slideshow-card'
      }
      case EulogiseProduct.TV_WELCOME_SCREEN: {
        return '#tv-welcome-screen-card'
      }
      case EulogiseProduct.THANK_YOU_CARD: {
        return '#a6-thankyou-card'
      }
    }
    return
  }

  public static getProductShortName({
    product,
    region,
  }: {
    product: EulogiseProduct
    region: EulogiseRegion
  }): EulogiseProductShortName {
    if (product === EulogiseProduct.BOOKLET && region === EulogiseRegion.USA) {
      return EulogiseProductShortName.BOOKLET_US
    }
    // @ts-ignore
    return EulogiseProductShortName[product]
  }

  public static getProductExportName({
    product,
    region,
  }: {
    product: EulogiseProduct
    region: EulogiseRegion
  }): EulogiseExportProductName {
    if (product === EulogiseProduct.BOOKLET && region === EulogiseRegion.USA) {
      return EulogiseExportProductName.BOOKLET_US
    }
    // @ts-ignore
    return EulogiseExportProductName[product]
  }

  public static getDefaultDeceasedContentByThemeId({
    product,
    themeId,
    region,
  }: {
    product?: EulogiseProduct
    themeId?: string
    region?: EulogiseRegion
  }): ICardPopulatedTextData {
    const defaultContent: ICardPopulatedTextData & {
      bookmarkPrimaryImage?: ICardPopulatedTextDataPrimaryImage
      thankyouCardPrimaryImage?: ICardPopulatedTextDataPrimaryImage
      tvWelcomeScreenPrimaryImage?: ICardPopulatedTextDataPrimaryImage
    } =
      (themeId
        ? getDefaultThemeDataByRegion(region!)[themeId]
        : CARD_PRODUCT_DEFAULT_THEME_DATA) ?? CARD_PRODUCT_DEFAULT_THEME_DATA
    const commonPrimaryImage = {
      filestackHandle: DEFAULT_DUMMY_IMAGE_FILESTACK_HANDLE,
    }
    if (product) {
      if (product === EulogiseProduct.BOOKMARK) {
        return {
          ...defaultContent,
          primaryImage: {
            ...commonPrimaryImage,
            ...defaultContent.primaryImage,
            ...defaultContent.bookmarkPrimaryImage,
          },
        }
      } else if (product === EulogiseProduct.THANK_YOU_CARD) {
        return {
          ...defaultContent,
          primaryImage: {
            ...commonPrimaryImage,
            ...defaultContent.primaryImage,
            ...defaultContent.thankyouCardPrimaryImage,
          },
        }
      } else if (product === EulogiseProduct.TV_WELCOME_SCREEN) {
        return {
          ...defaultContent,
          primaryImage: {
            ...commonPrimaryImage,
            ...defaultContent.primaryImage,
            ...(defaultContent as any).tvWelcomeScreenPrimaryImage,
          },
        }
      }
    }
    return {
      ...defaultContent,
      primaryImage: {
        ...commonPrimaryImage,
        ...defaultContent.primaryImage,
      },
    }
  }

  private static getMaxImageHeight(product: EulogiseProduct, height?: number) {
    if (height === undefined) {
      return
    }
    const MAX_HEIGHT = 360
    const MAX_TV_WELCOME_SCREEN_HEIGHT = 300
    if (product === EulogiseProduct.TV_WELCOME_SCREEN) {
      return Math.min(height, MAX_TV_WELCOME_SCREEN_HEIGHT)
    }
    return Math.min(height, MAX_HEIGHT)
  }

  public static getRowByPagesAndRowId({
    pages,
    rowId,
  }: {
    pages: Array<ICardProductPage>
    rowId: string
  }): ICardProductRow | undefined {
    for (let page of pages) {
      for (let row of page.rows) {
        if (row.id === rowId) {
          return row
        }
      }
    }
    return
  }

  public static getRowById({
    cardProduct,
    rowId,
  }: {
    cardProduct: ICardProductData
    rowId: string
  }): ICardProductRow | undefined {
    return this.getRowByPagesAndRowId({
      pages: cardProduct.content?.pages,
      rowId,
    })
  }

  public static getFirstFrameContentIdByRowId(
    cardProduct: ICardProductData,
    rowId: string,
  ): string | undefined {
    const row = this.getRowById({ cardProduct, rowId })
    if (!row) {
      return undefined
    }
    return CardProductFrameHelper.getFirstContentId(
      (row.data as ICardProductFrameRowData).content as ICardProductFrameItem,
    )
  }

  public static getImageSizeByProduct({
    orgImageWidth,
    orgImageHeight,
    product,
    productTheme,
    currentHeight,
    region,
  }: {
    orgImageWidth: number
    orgImageHeight: number
    product: EulogiseProduct
    productTheme: ICardProductTheme
    currentHeight: number
    region: EulogiseRegion
  }) {
    let height: number, width: number
    const orgImageScale = orgImageWidth / orgImageHeight
    const isLandscape = orgImageScale > 1
    const isPortrait = orgImageScale < 1
    const { width: pageContentWidth } =
      CardProductHelper.getDefaultPageContentWidthAndHeight({
        product,
        defaultThemeLayoutColumns: productTheme?.defaultThemeLayoutColumns,
        region,
      })

    if (isLandscape) {
      width =
        orgImageWidth > pageContentWidth ? pageContentWidth : orgImageWidth
      const scale = orgImageHeight / orgImageWidth
      const newHeight = scale * width
      if (orgImageHeight > newHeight) {
        height = newHeight
        width = newHeight * (orgImageWidth / orgImageHeight)
      } else {
        height = newHeight
      }
    } else if (isPortrait) {
      height = (
        orgImageHeight > currentHeight ? currentHeight : orgImageHeight
      )!
      const scale = orgImageWidth / orgImageHeight
      width = scale * height!
      if (width > pageContentWidth) {
        width = pageContentWidth
        height = pageContentWidth * (orgImageHeight / orgImageWidth)
      }
    }
    // square image
    else {
      height = currentHeight!
      width = currentHeight!
    }
    return {
      width,
      height,
    }
  }

  public static getImageSizeWithPresetHeight({
    defaultPrimaryImageHeight,
    primaryImageHeight,
    primaryImageWidth,
    product,
    defaultThemeLayoutColumns,
    region = EulogiseRegion.AU,
  }: {
    defaultPrimaryImageHeight: number
    primaryImageHeight: number
    primaryImageWidth: number
    product: EulogiseProduct
    defaultThemeLayoutColumns?: number
    region?: EulogiseRegion
  }): {
    width: number
    height: number
  } {
    const adjustedImageHeight = this.getMaxImageHeight(
      product,
      defaultPrimaryImageHeight,
    )
    primaryImageWidth =
      primaryImageHeight && primaryImageWidth
        ? this.getScaledPrimaryImageWidthByHeight(
            {
              height: primaryImageHeight,
              width: primaryImageWidth,
            },
            adjustedImageHeight!,
          ).width
        : primaryImageWidth
    const maxImageWidth = this.getDefaultPageContentWidthAndHeight({
      product,
      defaultThemeLayoutColumns,
      region,
    }).width
    primaryImageWidth =
      primaryImageWidth > maxImageWidth ? maxImageWidth : primaryImageWidth
    return {
      width: primaryImageWidth,
      height: adjustedImageHeight!,
    }
    /*const { width: adjustedImageWidth, height: adjustedImageHeight } =
      this.getImageSizeByProduct({
        product,
        themeId,
        orgImageHeight: primaryImage?.height!,
        orgImageWidth: primaryImage?.width!,
        currentHeight: defaultContent.primaryImage?.height,
      })*/
  }

  public static createPrimaryImageTemplateObject(
    props: CreatePrimaryImageTemplateProps,
  ): ICardProductFrameRowData {
    return JSON.parse(this.createPrimaryImageTemplate(props))
  }

  public static createPrimaryImageTemplate({
    defaultPrimaryImage,
    primaryImage,
    isCurrentImage,
    isPrimaryImage,
    defaultThemeLayoutColumns,
    product,
    region,
  }: CreatePrimaryImageTemplateProps) {
    const borderRadius = defaultPrimaryImage?.borderRadius || '0px'
    const rowContentHeight =
      defaultPrimaryImage?.height ?? DEFAULT_ORIGINAL_FRAME_SIZE
    const rowContentWidth =
      defaultPrimaryImage?.width ?? DEFAULT_ORIGINAL_FRAME_SIZE

    const { height: adjustedImageHeight, width: adjustedImageWidth } =
      this.getImageSizeWithPresetHeight({
        defaultPrimaryImageHeight: defaultPrimaryImage?.height!,
        defaultThemeLayoutColumns,
        product,
        primaryImageWidth: primaryImage?.width!,
        primaryImageHeight: primaryImage?.height!,
        region,
      })

    // if image template
    if (defaultPrimaryImage?.type === CardProductContentItemType.IMAGE) {
      return `{
        ${
          primaryImage?.filename
            ? `"filename": "${primaryImage?.filename}",`
            : ''
        }
        ${
          primaryImage?.filepath
            ? `"filepath": "${primaryImage?.filepath}",`
            : ''
        }
        ${
          primaryImage?.filestackHandle
            ? `"filestackHandle": "${primaryImage?.filestackHandle}",`
            : ''
        }
        ${
          adjustedImageHeight ? `"height": ${adjustedImageHeight ?? null},` : ''
        }
        ${adjustedImageWidth ? `"width": ${adjustedImageWidth ?? null},` : ''}
        "alignment": "center",
        "imageType": "${
          isCurrentImage
            ? ICardProductImageType.CURRENT_IMAGE
            : isPrimaryImage
            ? ICardProductImageType.PRIMARY_IMAGE
            : ICardProductImageType.DEFAULT_THEME_IMAGE
        }"
      }`
    } else {
      // frame template
      return `{ "isFullWidth": ${!!primaryImage?.isFullWidth}, "enableBorder": ${
        (primaryImage as any)?.enableBorder ?? false
      }, "width": ${adjustedImageWidth ?? null}, "height": ${
        adjustedImageHeight ?? null
      }, "content": { "lockAspectRatio": ${
        (primaryImage as ICardPopulatedTextDataPrimaryImage)?.lockAspectRatio ??
        false
      }, "width": ${rowContentWidth ?? null}, "height": ${
        rowContentHeight ?? null
      }, "type": "rows", "items": [{ "borderRadius": "${borderRadius}", "id": "lsx8vp0i", "type": "content", "content": { "type": "image","imageType": "${
        isCurrentImage
          ? ICardProductImageType.CURRENT_IMAGE
          : isPrimaryImage
          ? ICardProductImageType.PRIMARY_IMAGE
          : ICardProductImageType.DEFAULT_THEME_IMAGE
      }"${
        primaryImage?.filename ? `,"filename": "${primaryImage?.filename}"` : ''
      }${
        primaryImage?.filepath ? `,"filepath": "${primaryImage?.filepath}"` : ''
      }${
        primaryImage?.filestackHandle
          ? `,"filestackHandle": "${primaryImage?.filestackHandle}"`
          : ''
      }}}],"id": "w58ut2pi"}}`
    }
  }

  public static convertDynamicTheme({
    product,
    themeId,
    productTheme,
    region,
    variables,
    currentImage,
    dateFormat,
  }: {
    product: EulogiseProduct
    themeId?: string
    productTheme: ICardProductTheme
    region?: EulogiseRegion
    variables?: ICardPopulatedTextData
    currentImage?: IImageAssetContent
    dateFormat?: string
  }): Array<ICardProductPage> {
    const templateString = JSON.stringify(productTheme.defaultContent)
    const defaultContent = this.getDefaultDeceasedContentByThemeId({
      product,
      themeId,
      region,
    })!

    const customTags: Mustache.OpeningAndClosingTags = ['"<<', '>>"']
    const defaultPrimaryImage = defaultContent.primaryImage!

    const primaryImage =
      currentImage || variables?.primaryImage || defaultPrimaryImage
    const isCurrentImage = !!currentImage
    const isPrimaryImage = !!variables?.primaryImage

    let primaryImageTemplate = ''

    const hasPrimaryImageTag = /<<&primaryImage>>/.test(templateString)
    if (hasPrimaryImageTag) {
      primaryImageTemplate = this.createPrimaryImageTemplate({
        primaryImage,
        defaultPrimaryImage,
        isCurrentImage,
        isPrimaryImage,
        defaultThemeLayoutColumns: productTheme?.defaultThemeLayoutColumns,
        product,
        region: variables?.region,
      })
    }

    const newDateFormat =
      dateFormat ??
      (region === EulogiseRegion.USA
        ? productTheme.metadata?.dateFormatUS
        : productTheme.metadata?.dateFormat) ??
      ''
    const dateOfBirth = variables?.dateOfBirth || defaultContent.dateOfBirth
    const dateOfDeath = variables?.dateOfDeath || defaultContent.dateOfDeath
    const dateOfService =
      variables?.dateOfService || defaultContent.dateOfService

    const newVariables = {
      ...defaultContent,
      ...UtilHelper.removeUndefinedFields(variables!),
      regionFileExt: region === EulogiseRegion.USA ? '_USA' : '',
      dateOfBirth: newDateFormat
        ? DateTimeHelper.formatDate(dateOfBirth, newDateFormat)
        : dateOfBirth,
      dateOfService: newDateFormat
        ? DateTimeHelper.formatDate(dateOfService, newDateFormat)
        : dateOfService,
      dateOfDeath: newDateFormat
        ? DateTimeHelper.formatDate(dateOfDeath, newDateFormat)
        : dateOfDeath,
      deceasedNameFontType: this.getThemeFontSizeByDeceasedNameLength(
        themeId as string,
        variables?.deceasedName?.length || 0,
        product as EulogiseProduct,
      ),
      primaryImageType:
        defaultPrimaryImage?.type ?? CardProductContentItemType.FRAME,
      primaryImage: primaryImageTemplate,
    }
    const templateReplacedString = Mustache.render(templateString, {
      ...newVariables,
      deceasedName: newVariables.deceasedName?.replace(/"/g, '\\"'),
    })
    const templateReplacedObject = Mustache.render(
      templateReplacedString,
      newVariables,
      {},
      customTags,
    )

    const result = JSON.parse(templateReplacedObject)
    if (!hasPrimaryImageTag) {
      // Some special treatment on primaryImage in Frame and Image
      // apply primaryImage to cover cases (Saved Theme) that does not have <<&primaryImage>> tag
      return result.map((page: any) => {
        return {
          ...page,
          rows: page.rows.map((row: ICardProductRow) => {
            if (row.type === CardProductContentItemType.FRAME) {
              // do not apply renderImageHeight, renderImageWidth, transformX and transformY fields even if the theme has these fields
              // otherwise, the primary image will get distorted
              // refer to https://trello.com/c/Gqwg31HW/1189-issues-with-round-frames-and-primary-image-pulls
              const {
                renderImageHeight,
                renderImageWidth,
                transformX,
                transformY,
                ...newPrimaryImageContent
              } = {
                ...(
                  (
                    row.data.content as
                      | ICardProductFrameColumnsItem
                      | ICardProductFrameRowsItem
                  ).items[0] as ICardProductFrameContentItem
                ).content,
                ...primaryImage,
                // @ts-ignore - not sure why we need this line
                imageType: ICardProductImageType.PRIMARY_IMAGE,
              } as ICardProductFrameImageContent
              const newRow = UtilHelper.setObject(
                'data.content.items[0].content',
                newPrimaryImageContent,
                row,
              ) as ICardProductFrameRow
              return newRow
            }
            // Don't Support Image Item for now
            /*            else if (row.type === CardProductContentItemType.IMAGE) {
              return UtilHelper.setObject(
                'data',
                {
                  //                  ...row.data,
                  ...primaryImage,
                },
                row,
              )
            }*/
            return row
          }) as Array<ICardProductRow>,
        }
      }) as Array<ICardProductPage>
    }
    return this.replacePagesNonPrimaryImagesWithDefaultFilestackHandle(result)
  }

  public static replaceFrameImagesWithDefaultFileStackHandle(
    frameItem: ICardProductFrameItem,
  ): ICardProductFrameItem {
    if (frameItem.type === 'rows' || frameItem.type === 'columns') {
      return {
        ...frameItem,
        items: frameItem.items.map((item) =>
          this.replaceFrameImagesWithDefaultFileStackHandle(item),
        ),
      }
    }
    if (frameItem.type === 'content') {
      return {
        ...frameItem,
        content: {
          ...frameItem.content,
          filestackHandle: DEFAULT_DUMMY_IMAGE_FILESTACK_HANDLE,
        } as ICardProductFrameImageContent,
      }
    }
    throw new Error('Invalid frame item type')
  }

  public static replacePageNonPrimaryImagesWithDefaultFilestackHandle(
    page: ICardProductPage,
  ) {
    return {
      ...page,
      rows: page.rows?.map((row: ICardProductRow) => {
        if (row.type === CardProductContentItemType.FRAME) {
          if (row.dynamicDataId === 'primaryImage') {
            return row
          }
          return {
            ...row,
            data: {
              ...row.data,
              content: this.replaceFrameImagesWithDefaultFileStackHandle(
                row.data.content as ICardProductFrameItem,
              ),
            },
          } as ICardProductFrameRow
        }
        return row
      }),
    }
  }

  public static replacePagesNonPrimaryImagesWithDefaultFilestackHandle(
    pages: Array<ICardProductPage>,
  ): Array<ICardProductPage> {
    return pages.map((page) =>
      this.replacePageNonPrimaryImagesWithDefaultFilestackHandle(page),
    )
  }

  private static createDynamicThemePages({
    product,
    themeId,
    productTheme,
    variables,
    currentImage,
    region,
  }: {
    product: EulogiseProduct
    themeId: string
    productTheme: ICardProductTheme
    variables?: ICardPopulatedTextData
    currentImage?: IImageAssetContent
    region?: EulogiseRegion
  }): Array<ICardProductPage> {
    return this.convertDynamicTheme({
      product,
      themeId,
      productTheme,
      variables,
      currentImage,
      region,
    })
  }

  public static createDynamicTheme({
    themeId,
    product,
    productTheme,
    variables,
    currentImage,
    region,
  }: {
    themeId: string
    product: EulogiseProduct
    productTheme: ICardProductTheme
    variables?: ICardPopulatedTextData
    currentImage?: IImageAssetContent
    region?: EulogiseRegion
  }): ICardProductTheme {
    if (!productTheme) {
      throw new Error('productTheme not found')
    }
    return {
      ...productTheme,
      defaultContent: productTheme.defaultContent
        ? this.createDynamicThemePages({
            product,
            themeId,
            productTheme,
            variables,
            currentImage,
            region,
          })
        : undefined,
    }
  }

  private static getThemeFontSizeByDeceasedNameLength(
    themeId: string,
    deceasedNameLength: number,
    product: EulogiseProduct,
  ) {
    const threshold = DYNAMIC_THEME_FONT_SIZE_ADAPTION_THRESHOLD[themeId]
    if (
      !threshold ||
      product === EulogiseProduct.SLIDESHOW ||
      product === EulogiseProduct.ALL
    ) {
      return
    }
    const {
      DECEASED_NAME: {
        ORIGIN_FONT_TYPE,
        ONE_LEVEL_SMALLER_FONT_TYPE,
        TWO_LEVEL_SMALLER_FONT_TYPE,
        ONE_LEVEL_STRING_LENGTH_THRESHOLD,
        TWO_LEVEL_STRING_LENGTH_THRESHOLD,
      },
    } = DYNAMIC_THEME_FONT_SIZE_ADAPTION_THRESHOLD[themeId]

    const originFontType = ORIGIN_FONT_TYPE[product]
    const oneLevelSmallerFontType = ONE_LEVEL_SMALLER_FONT_TYPE[product]
    const twoLevelSmallerFontType = TWO_LEVEL_SMALLER_FONT_TYPE[product]
    const oneLevelStringLengthThreshold =
      ONE_LEVEL_STRING_LENGTH_THRESHOLD[product]
    const twoLevelStringLengthThreshold =
      TWO_LEVEL_STRING_LENGTH_THRESHOLD[product]

    if (deceasedNameLength > twoLevelStringLengthThreshold) {
      return twoLevelSmallerFontType
    } else if (deceasedNameLength > oneLevelStringLengthThreshold) {
      return oneLevelSmallerFontType
    }
    return originFontType
  }

  private static getScaledPrimaryImageWidthByHeight(
    imageSize: IImageSize,
    height: number,
  ): IImageSize {
    if (!height || !imageSize) {
      return imageSize
    }
    const coefficient = height / imageSize?.height
    const scaledWidth = Math.floor(imageSize?.width * coefficient)
    return {
      height: imageSize.height,
      width: scaledWidth,
    }
  }

  public static getCardProductWidthAndHeightInScale({
    product,
    defaultThemeLayoutColumns,
    height,
  }: {
    product: EulogiseProduct
    defaultThemeLayoutColumns?: number
    height: number
  }) {
    const {
      pageWidth: originalProductWidth,
      pageHeight: originalProductHeight,
    } = this.getPageWidthAndHeightByProduct({
      product,
      defaultThemeLayoutColumns,
    })
    const scale = originalProductWidth / originalProductHeight

    return { width: scale * height, height }
  }

  public static getCurrentImage(
    existingCardProduct?: ICardProductData,
  ): IImageAssetContent | undefined {
    if (!existingCardProduct) {
      return
    }
    let currentImage: ICardProductImageRowData | undefined = undefined
    existingCardProduct.content.pages.forEach((p: ICardProductPage) => {
      p.rows.forEach((r: ICardProductRow) => {
        if (r.type === CardProductContentItemType.IMAGE) {
          if (r.data.imageType === ICardProductImageType.CURRENT_IMAGE) {
            currentImage = r.data
          }
        }
      })
    })
    return currentImage ? (currentImage as IImageAssetContent) : undefined
  }

  public static getCardProductThemeWithPopulatedData({
    product,
    themeId,
    theme,
    region,
    populatedData,
    existingCardProduct,
  }: {
    product: EulogiseProduct
    themeId: string
    theme: ITheme
    region: EulogiseRegion
    populatedData?: ICardPopulatedTextData
    existingCardProduct?: ICardProductData
  }): ICardProductTheme | ISlideshowTheme | undefined {
    const currentImage = this.getCurrentImage(existingCardProduct)
    switch (product) {
      case EulogiseProduct.BOOKLET:
      case EulogiseProduct.BOOKMARK:
      case EulogiseProduct.SIDED_CARD:
      case EulogiseProduct.TV_WELCOME_SCREEN:
      case EulogiseProduct.THANK_YOU_CARD:
        const productTheme = ThemeHelper.getProductThemeByProductType({
          theme,
          product,
          region,
        }) as ICardProductTheme
        return this.createDynamicTheme({
          themeId,
          product,
          productTheme: {
            ...productTheme,
            defaultContent:
              this.replacePagesNonPrimaryImagesWithDefaultFilestackHandle(
                productTheme.defaultContent!,
              ),
          } as ICardProductTheme,
          variables: { ...populatedData, region },
          currentImage,
          region,
        })
      case EulogiseProduct.SLIDESHOW:
        return ThemeHelper.getProductThemeByProductType({
          theme,
          product,
          region,
        }) as ISlideshowTheme
      default:
        throw new Error('no such product type')
    }
  }

  public static updateFrameImageContent({
    imageContent,
    frameImageItem,
  }: {
    imageContent: IImageAssetContent
    frameImageItem: ICardProductFrameImageContent
  }): ICardProductFrameImageContent {
    // make sure renderImageWidth, renderImageHeight, transformX and transformY fields will not carry back to the new image content
    const { filename, filestackHandle, filepath, type, isFullWidth } = {
      ...frameImageItem,
      ...imageContent,
    }
    return {
      filename,
      filestackHandle,
      filepath,
      type,
      isFullWidth,
    } as ICardProductFrameImageContent
  }

  public static updateFrameImageByContentIdInFrameItem({
    frameContentId,
    imageContent,
    frameItem,
  }: {
    frameContentId: string
    imageContent: IImageAssetContent
    frameItem: ICardProductFrameItem
  }): ICardProductFrameItem {
    if (frameItem.type === 'rows' || frameItem.type === 'columns') {
      return {
        ...frameItem,
        items: (frameItem as ICardProductFrameColumnsItem).items.map((item) =>
          this.updateFrameImageByContentIdInFrameItem({
            frameContentId,
            frameItem: item,
            imageContent,
          }),
        ),
      }
    }
    // when id is found
    if (frameItem.type === 'content' && frameContentId === frameItem.id) {
      return {
        ...frameItem,
        content: this.updateFrameImageContent({
          imageContent,
          frameImageItem: frameItem.content as ICardProductFrameImageContent,
        }),
      }
    }
    // if it is a different frame content item
    return frameItem
  }

  public static updateFrameImageByContentIdInRowData({
    rowData,
    frameContentId,
    imageContent,
  }: {
    rowData: ICardProductFrameRowData
    frameContentId: string
    imageContent: IImageAssetContent
  }): ICardProductFrameRowData {
    const frameData = rowData as ICardProductFrameRowData
    return {
      ...frameData,
      content: this.updateFrameImageByContentIdInFrameItem({
        frameItem: frameData.content,
        frameContentId,
        imageContent,
      }),
    }
  }

  public static updateFrameImageByContentIdInPage({
    page,
    frameContentId,
    imageContent,
  }: {
    page: ICardProductPage
    frameContentId: string
    imageContent: IImageAssetContent
  }): ICardProductPage {
    return {
      ...page,
      rows: page.rows.map((row) => {
        if (row.type === CardProductContentItemType.FRAME) {
          const newFrameRowData = this.updateFrameImageByContentIdInRowData({
            rowData: row.data,
            frameContentId,
            imageContent,
          })
          return {
            ...row,
            data: newFrameRowData,
          }
        }
        return row
      }),
    }
  }

  public static updateFrameImageByContentId({
    cardProduct,
    frameContentId,
    imageContent,
  }: {
    cardProduct: ICardProductContent
    frameContentId: string
    imageContent: IImageAssetContent
  }): ICardProductContent {
    const pages = cardProduct.pages
    return {
      ...cardProduct,
      pages: pages.map((page) =>
        this.updateFrameImageByContentIdInPage({
          page,
          frameContentId,
          imageContent,
        }),
      ),
    }
  }

  public static updateCardProductPages({
    page,
    updatedRows,
    cardProduct,
    pageIndex,
  }: {
    page: ICardProductPage
    updatedRows: any
    cardProduct: ICardProductData
    pageIndex: number
  }): Array<any> {
    const updatedPage = { ...page, rows: updatedRows }

    return [
      ...cardProduct.content.pages.slice(0, pageIndex),
      updatedPage,
      ...cardProduct.content.pages.slice(pageIndex + 1),
    ].filter(Boolean)
  }

  public static getAddCardProductRowState(
    cardProductState: ICardProductState,
    pageIndex: number,
    row: ICardProductRow,
  ) {
    const activeCardProduct = cardProductState.activeItem
    const page: ICardProductPage = activeCardProduct?.content.pages[pageIndex]!
    const rows = page.rows.concat(row)
    return UtilHelper.mergeDeepRight(cardProductState, {
      activeItem: {
        content: {
          pages: CardProductHelper.updateCardProductPages({
            page,
            updatedRows: rows,
            cardProduct: activeCardProduct!,
            pageIndex,
          }),
        },
      },
    })
  }

  public static getRemoveCardProductPagesState(
    cardProductState: ICardProductState,
  ): ICardProductState {
    const existingPages = cardProductState.activeItem?.content.pages!
    const totalPages = existingPages.length
    const removePages = 4
    const frontPages = existingPages.slice(0, totalPages - 1 - removePages)
    const lastPage = existingPages[existingPages.length - 1]
    return UtilHelper.mergeDeepRight(cardProductState, {
      activeItem: {
        content: {
          pages: [...frontPages, lastPage],
        },
      },
    })
  }

  public static getDummyImage(pageContentWidth: number, maxImages: number) {
    const padding = 3
    const dummyImageDimension =
      (pageContentWidth - maxImages * padding) / maxImages

    return {
      filename: 'dummy-file',
      filepath: 'primaryImages/mFMLqO1RSYylJwRWp5Rx.jpeg',
      width:
        dummyImageDimension > MAX_DUMMY_IMAGE_DIMENSION
          ? MAX_DUMMY_IMAGE_DIMENSION
          : dummyImageDimension,
      height:
        dummyImageDimension > MAX_DUMMY_IMAGE_DIMENSION
          ? MAX_DUMMY_IMAGE_DIMENSION
          : dummyImageDimension,
    }
  }

  private static createCardProductNewPageRows(
    type: 'image' | 'text' | 'frame',
    suffix: string,
    region: EulogiseRegion,
    newPageStyles?: ICardProductNewPageStyles,
    themeId?: string,
  ): Array<ICardProductRow> {
    const assignRowId = (row: ICardProductRow) => {
      return Object.assign(
        {},
        {
          ...row,
          id: `${row.id}${suffix}`,
        },
      )
    }
    if (type === CardProductContentItemType.IMAGE) {
      return CARD_PRODUCT_NEW_PAGE_IMAGE_ROWS(newPageStyles!, region).map(
        assignRowId,
      )
    } else if (type === CardProductContentItemType.FRAME) {
      return CARD_PRODUCT_NEW_PAGE_FRAMES_ROWS(region).map(assignRowId)
    }
    return CARD_PRODUCT_NEW_PAGE_TEXT_ROWS(
      newPageStyles!,
      themeId!,
      region,
    ).map(assignRowId)
  }

  public static getAddCardProductPagesState({
    cardProductState,
    productTheme,
    themeId,
    region,
  }: {
    cardProductState: ICardProductState
    productTheme: ICardProductTheme
    themeId: string
    region: EulogiseRegion
  }): ICardProductState {
    const existingPages = cardProductState.activeItem?.content.pages!
    const frontPages = existingPages.slice(0, existingPages.length - 1)
    const lastPage = existingPages[existingPages.length - 1]
    const secondPage = frontPages[1]
    const thirdPage = frontPages[2]
    const pageNo: number = frontPages.length
    const getTextPage = (pageNo: number, page: any) => ({
      ...page,
      rows: this.createCardProductNewPageRows(
        CardProductContentItemType.TEXT,
        `${pageNo}`,
        region,
        productTheme.newPageStyles,
        themeId,
      ),
    })
    const getImagePage = (pageNo: number, page: any) => ({
      ...page,
      rows: this.createCardProductNewPageRows(
        CardProductContentItemType.IMAGE,
        `${pageNo}`,
        region,
        productTheme.newPageStyles,
        themeId,
      ),
    })
    const getFramePage = (pageNo: number, page: any) => ({
      ...page,
      rows: this.createCardProductNewPageRows(
        CardProductContentItemType.FRAME,
        `${pageNo}`,
        region,
      ),
    })
    const evenPage = secondPage || lastPage
    const oddPage = thirdPage || lastPage
    return UtilHelper.mergeDeepRight(cardProductState, {
      activeItem: {
        content: {
          pages: this.replacePagesNonPrimaryImagesWithDefaultFilestackHandle([
            ...frontPages,
            getTextPage(pageNo + 1, evenPage),
            getFramePage(pageNo + 2, oddPage),
            getImagePage(pageNo + 3, evenPage),
            getTextPage(pageNo + 4, oddPage),
            lastPage,
          ]),
        },
      },
    })
  }

  public static getUpdateBookletRowState({
    cardProductState,
    pageIndex,
    rowId,
    rowData,
    frameContentItemId,
  }: {
    cardProductState: ICardProductState
    pageIndex: number
    rowId: string
    rowData: ICardProductRowData
    frameContentItemId?: string
  }): ICardProductState {
    const activeCardProduct = cardProductState.activeItem
    const page: ICardProductPage = activeCardProduct?.content.pages[pageIndex]!
    const rows = page.rows
    const updateRow: ICardProductRow = CardProductHelper.getCardProductRow(
      activeCardProduct!,
      pageIndex,
      rowId,
    )

    return UtilHelper.mergeDeepRight(cardProductState, {
      activeItem: {
        content: {
          pages: CardProductHelper.updateCardProductPages({
            page,
            updatedRows: rows.map((r: ICardProductRow) => {
              if (r.id === rowId) {
                if (r.type === 'frame') {
                  const frameItemData: ICardProductFrameItem = (
                    updateRow.data as any
                  ).content!
                  return {
                    ...updateRow,
                    data: {
                      ...updateRow.data,
                      content:
                        CardProductHelper.getUpdatedRowContentDataForFrame({
                          framePayload: {
                            frameContentItemId: frameContentItemId!,
                            rowId,
                            pageIndex,
                            image: rowData as IImageAssetContent,
                          },
                          frameItemData,
                        }),
                    },
                  }
                }
                if (r.type === 'columns') {
                  return {
                    ...updateRow,
                    data: {
                      ...updateRow.data,
                      items: (
                        updateRow.data as ICardProductColumnRowData
                      ).items.map((item: ICardProductImageRow, index: number) =>
                        UtilHelper.mergeDeepRight(item, {
                          data: (rowData as ICardProductColumnRowData).items[
                            index
                          ].data,
                        }),
                      ),
                    },
                  }
                }
                if (r.type === 'image') {
                  return UtilHelper.mergeDeepRight(updateRow, {
                    data: {
                      ...rowData,
                      ...((r.data as ICardProductImageRowData).imageType
                        ? { imageType: ICardProductImageType.CURRENT_IMAGE }
                        : {}),
                    } as ICardProductImageRowData,
                  })
                }
                return UtilHelper.mergeDeepRight(updateRow, {
                  data: rowData,
                })
              }
              return r
            }),
            cardProduct: activeCardProduct!,
            pageIndex,
          }),
        },
      },
    })
  }

  public static getBookletPageSizeByRegion(
    region: EulogiseRegion = EulogiseRegion.AU,
  ) {
    if (region === EulogiseRegion.USA) {
      return CardProductPageSize.HALF_LETTER_A5
    }
    return CardProductPageSize.A5
  }

  public static getEulogiseProductPaperSizeByRegion({
    product,
    paperSize,
  }: {
    product: EulogiseCardProducts
    paperSize: CardProductPageSize
  }): EulogiseRegion {
    if (
      product === EulogiseCardProducts.BOOKLET &&
      paperSize === CardProductPageSize.HALF_LETTER_A5
    ) {
      return EulogiseRegion.USA
    }
    return EulogiseRegion.AU
  }

  public static getRegionByPageSize(
    pageSize?: CardProductPageSize,
  ): EulogiseRegion {
    if (pageSize === CardProductPageSize.A5) {
      return EulogiseRegion.AU
    }
    return EulogiseRegion.USA
  }

  public static getUpdatedRegionByOldRegion(
    currentRegion: EulogiseRegion,
  ): EulogiseRegion {
    switch (currentRegion) {
      case EulogiseRegion.AU:
        return EulogiseRegion.USA
      case EulogiseRegion.USA:
        return EulogiseRegion.AU
      default:
        return EulogiseRegion.AU
    }
  }

  public static getDefaultPageSize({
    product,
    defaultThemeLayoutColumns,
    region,
  }: {
    product: EulogiseProduct
    defaultThemeLayoutColumns?: number
    region?: EulogiseRegion
  }): CardProductPageSize {
    if (EulogiseProduct.THANK_YOU_CARD === product) {
      const pageColumns = defaultThemeLayoutColumns ?? 1
      return THANK_YOU_CARD_PAGE_SIZE[pageColumns] as CardProductPageSize
    } else if (EulogiseProduct.TV_WELCOME_SCREEN === product) {
      const pageColumns = defaultThemeLayoutColumns ?? 1
      return TV_WELCOME_SCREEN_PAGE_SIZE[pageColumns] as CardProductPageSize
    }
    // @ts-ignore
    return {
      [EulogiseProduct.BOOKLET]: this.getBookletPageSizeByRegion(region),
      [EulogiseProduct.SIDED_CARD]: this.getBookletPageSizeByRegion(region),
      [EulogiseProduct.BOOKMARK]: CardProductPageSize.BOOKMARK,
    }[product]
  }

  public static getPageWidthAndHeightByProduct({
    product,
    defaultThemeLayoutColumns,
    pageOrientation = CardProductPageOrientation.PORTRAIT,
    region,
  }: {
    product: EulogiseProduct
    defaultThemeLayoutColumns?: number
    pageOrientation?: CardProductPageOrientation
    region?: EulogiseRegion
  }): {
    pageWidth: number
    pageHeight: number
  } {
    const isPortrait = pageOrientation === CardProductPageOrientation.PORTRAIT
    const pageSize = this.getDefaultPageSize({
      product,
      defaultThemeLayoutColumns,
      region,
    })
    const pageWidth = PAGE_SIZES[pageSize][isPortrait ? 0 : 1]
    const pageHeight = PAGE_SIZES[pageSize][isPortrait ? 1 : 0]
    return {
      pageWidth,
      pageHeight,
    }
  }

  public static getPageWidthAndHeight(
    pageSize: CardProductPageSize,
    pageOrientation: CardProductPageOrientation,
  ): { pageWidth: number; pageHeight: number } {
    const isPortrait = pageOrientation === CardProductPageOrientation.PORTRAIT
    const pageWidth = PAGE_SIZES[pageSize][isPortrait ? 0 : 1]
    const pageHeight = PAGE_SIZES[pageSize][isPortrait ? 1 : 0]
    return { pageWidth, pageHeight }
  }

  public static getPageBackgroundPosition(
    backgroundImages: string = '',
    bleedPageMode: BleedPageMode,
  ) {
    const newBackgroundImageString = backgroundImages
      .replace(/\([^()]*\)/g, '')
      // need to do two times to remove all the nested css function
      // e.g. linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), url("https://us.media.eulogisememorials.com/backgroundImages/Beach/AU/Beach_BOOKLET_LEFT.jpg")
      .replace(/\([^()]*\)/g, '')

    const noOfBackgroundImages = newBackgroundImageString.split(',').length
    if (noOfBackgroundImages === 0) {
      return ''
    }
    const prefix = UtilHelper.times(
      () => 'center center',
      noOfBackgroundImages - 1,
    ).join(', ')
    if (bleedPageMode === BleedPageMode.LEFT_SIDE_BLEED) {
      return [prefix, 'right center'].filter(Boolean).join(', ')
    } else if (bleedPageMode === BleedPageMode.RIGHT_SIDE_BLEED) {
      return [prefix, 'left center'].filter(Boolean).join(', ')
    } else if (bleedPageMode === BleedPageMode.FULL_BLEED) {
      return [prefix, 'center center'].filter(Boolean).join(', ')
    }
    return [prefix, 'center center'].filter(Boolean).join(', ')
  }

  public static getPageWidthByCardProduct(
    cardProduct: ICardProductData,
  ): number {
    const cardProductContent = cardProduct?.content
    const cardProductPageSize = cardProductContent?.pageSize
    const cardProductPageOrientation = cardProductContent?.pageOrientation
    const { pageWidth } = this.getPageWidthAndHeight(
      cardProductPageSize,
      cardProductPageOrientation,
    )
    return pageWidth
  }

  public static getEditorWidth({
    cardProduct,
    isMobile = false,
    minWidth = 0,
  }: {
    cardProduct: ICardProductData
    isMobile: boolean
    minWidth?: number
  }): number {
    const cardProductContent = cardProduct?.content
    const cardProductPageSize = cardProductContent?.pageSize
    const cardProductPageOrientation = cardProductContent?.pageOrientation
    const noOfPages = cardProductContent?.pages.length
    const { pageWidth } = this.getPageWidthAndHeight(
      cardProductPageSize,
      cardProductPageOrientation,
    )
    const pageWidthWithDraggable = pageWidth + 30
    if (
      cardProductContent.pageSize === CardProductPageSize.THANKYOUCARD_2_COLS ||
      cardProductContent.pageSize ===
        CardProductPageSize.TV_WELCOME_SCREEN_2_COLS
    ) {
      if (minWidth && minWidth > pageWidthWithDraggable * 2) {
        return minWidth
      }
      return pageWidthWithDraggable * 2
    }
    if (!isMobile && noOfPages && noOfPages > 2) {
      if (minWidth && minWidth > pageWidthWithDraggable * 2) {
        return minWidth
      }
      return pageWidthWithDraggable * 2
    }
    if (minWidth && minWidth > pageWidthWithDraggable) {
      return minWidth
    }
    return pageWidthWithDraggable
  }

  public static isWrapperBorderVisible = ({
    isFocused,
    isHovered,
    resizing,
    isAnyRowFocused,
    enables,
  }: {
    isFocused: boolean
    isHovered: boolean
    resizing: boolean
    isAnyRowFocused: boolean
    enables: object
  }): boolean => {
    const hasEnabled = Object.values(enables ?? {}).includes(true)
    if (hasEnabled) {
      return true
    }

    if (!isAnyRowFocused && isHovered) {
      return true
    } else if (isAnyRowFocused && (isFocused || resizing)) {
      return true
    }
    return false
  }

  public static calculateContentItemTotalHeight = (
    elementHeight: number,
    margin: MarginType,
    editorScaledFactor: number = 1,
  ): number => {
    if (typeof margin === 'number') {
      return (elementHeight + margin * 2) * editorScaledFactor
    } else if (Array.isArray(margin)) {
      switch (margin.length) {
        case 2:
          return (elementHeight + margin[0] * 2) * editorScaledFactor
        case 3:
          return (elementHeight + margin[0] + margin[2]) * editorScaledFactor
        case 4:
          return (elementHeight + margin[0] + margin[1]) * editorScaledFactor
      }
    }
    return elementHeight * editorScaledFactor
  }

  public static getPageContentWidthAndHeight({
    product,
    pageSize,
    pageOrientation,
    region,
  }: {
    product: EulogiseProduct
    pageSize: CardProductPageSize
    pageOrientation: CardProductPageOrientation
    region: EulogiseRegion
  }): IPageContentWidthAndHeight {
    const { pageWidth, pageHeight } = this.getPageWidthAndHeight(
      pageSize,
      pageOrientation,
    )
    const [xMargin, yMargin] =
      region === EulogiseRegion.USA
        ? (this.getDefaultUSPageMargins(product) as Array<number>)
        : (this.getDefaultAUPageMargins(product) as Array<number>)
    return { width: pageWidth - xMargin * 2, height: pageHeight - yMargin * 2 }
  }

  public static getDefaultPageContentWidthAndHeight({
    product,
    defaultThemeLayoutColumns,
    region,
  }: {
    product: EulogiseProduct
    defaultThemeLayoutColumns?: number
    region: EulogiseRegion
  }): IPageContentWidthAndHeight {
    const pageSize = this.getDefaultPageSize({
      product,
      defaultThemeLayoutColumns,
      region,
    })
    return this.getPageContentWidthAndHeight({
      product,
      pageSize,
      pageOrientation: this.getDefaultPageOrientation(),
      region,
    })
  }

  // page1, page2, page3, page4, page5, page6, page7, page8, page9, page10, page11, page12, page13, page14, page15, page16

  // no of pages per chunk is 4 (page16, page1, page2 and page15)
  // i = 0   x=16              // x is noOfPages
  // page16 (x - i - 1), page1 (i),
  // page2 (i + 1), page15 (x - i - 2)

  // i = 2    x=16
  // page14 (x - i - 1), page3 (i),
  // page4 (i + 1), page13 (x - i - 2),

  // i = 4    x = 16
  // page12 (x - i - 1), page5 (i),
  // page6 (i + 1), page11 (x - i - 2),

  // i = 6    x = 16
  // page10 (x - i - 1), page7 (i),
  // page8 (i + 1), page9 (x - i - 2)

  public static getBookletPageOrder(pages: Array<ICardProductPage>) {
    const noOfPages = pages.length
    const noOfPagesPerChunk = 4
    const chunks = Math.ceil(noOfPages / noOfPagesPerChunk)
    const result = []

    for (let i = 0, times = 0; times < chunks; i += 2, times++) {
      // page16
      result.push(pages[noOfPages - i - 1])
      // page1
      result.push(pages[i])
      // page2
      result.push(pages[i + 1])
      // page15
      result.push(pages[noOfPages - i - 2])
    }

    return result
  }

  public static getPagesOrder({
    product,
    displayMode = CardProductViewDisplayMode.EDIT,
    pages,
  }: {
    product: EulogiseProduct
    displayMode: CardProductViewDisplayMode
    pages: Array<ICardProductPage>
  }): Array<ICardProductPage> {
    if (displayMode === CardProductViewDisplayMode.PRINT) {
      if (product === EulogiseProduct.BOOKLET) {
        return this.getBookletPageOrder(pages)
      }
      if (product === EulogiseProduct.SIDED_CARD) {
        const clonePages = [...pages]
        const lastPage = pages[pages.length - 1]
        const firstPage = pages[0]
        clonePages.pop()
        clonePages.shift()
        return [lastPage, ...clonePages, firstPage]
      }
    }
    return pages
  }

  public static getTextItemFont({
    rowStyle,
    blockType,
    productTheme,
  }: {
    rowStyle?: IRowStyle
    blockType: string // please refer DraftBlockType in draft js
    productTheme: ICardProductTheme
  }): string {
    const { defaultStyle, styles, metadata } = productTheme
    const baseFont: string | undefined = FontHelper.getFontNameById(
      metadata?.baseFont,
    )
    return (
      rowStyle?.font ??
      styles[blockType]?.font ??
      baseFont ??
      defaultStyle.font!
    )
  }

  public static getTextItemFontSize({
    rowStyle,
    blockType,
    productTheme,
  }: {
    rowStyle?: IRowStyle
    blockType: string // please refer DraftBlockType in draft js
    productTheme: ICardProductTheme
  }) {
    const { defaultStyle, styles } = productTheme
    return (
      rowStyle?.fontSize ??
      styles[blockType]?.fontSize ??
      defaultStyle.fontSize!
    )
  }

  public static getPageModeByPageSize({
    pageSize,
    product,
    displayMode = CardProductViewDisplayMode.EDIT,
    isMobile = false,
  }: {
    pageSize: CardProductPageSize
    product: EulogiseProduct
    displayMode?: CardProductViewDisplayMode
    isMobile?: boolean
  }): CardProductPageMode {
    if (displayMode === CardProductViewDisplayMode.PRINT) {
      if (product === EulogiseProduct.BOOKLET) {
        if (isMobile) {
          return CardProductPageMode.NORMAL
        }
        return CardProductPageMode.TWO_PAGES
      }
    }
    if (
      pageSize === CardProductPageSize.THANKYOUCARD_2_COLS ||
      pageSize === CardProductPageSize.TV_WELCOME_SCREEN_2_COLS
    ) {
      return CardProductPageMode.TWO_PAGES
    }
    return CardProductPageMode.NORMAL
  }

  public static getDefaultBorderMargin(product: EulogiseProduct): {
    marginX: number
    marginY: number
  } {
    const [marginX, marginY] = this.getDefaultOverlayByProduct(product)
      .overlayMargin as [number, number]
    return {
      marginX,
      marginY,
    }
  }

  public static getPageColModeByPageSize(
    pageSize: CardProductPageSize,
  ): CardProductPageColMode {
    if (
      pageSize === CardProductPageSize.THANKYOUCARD_2_COLS ||
      pageSize === CardProductPageSize.TV_WELCOME_SCREEN_2_COLS
    ) {
      return CardProductPageColMode.TWO_COLS
    }
    return CardProductPageColMode.ONE_COL
  }

  public static getNoOfDisplayPagesByPageMode(
    pageColMode: CardProductPageColMode,
  ) {
    if (pageColMode === CardProductPageColMode.TWO_COLS) {
      return 2
    }
    return 1
  }

  // AU PageMargins
  public static getDefaultAUPageMargins(product: EulogiseProduct) {
    // @ts-ignore
    return {
      [EulogiseProduct.BOOKLET]: [30, 40],
      [EulogiseProduct.SIDED_CARD]: [30, 40],
      [EulogiseProduct.BOOKMARK]: [5, 30],
      [EulogiseProduct.THANK_YOU_CARD]: [15, 20],
      [EulogiseProduct.TV_WELCOME_SCREEN]: [15, 20],
    }[product]
  }

  // US PageMargins
  public static getDefaultUSPageMargins(product: EulogiseProduct) {
    // @ts-ignore
    return {
      [EulogiseProduct.BOOKLET]: [30, 40],
      [EulogiseProduct.SIDED_CARD]: [30, 40],
      [EulogiseProduct.BOOKMARK]: [5, 30],
      [EulogiseProduct.THANK_YOU_CARD]: [15, 20],
      [EulogiseProduct.TV_WELCOME_SCREEN]: [15, 20],
    }[product]
  }

  public static getDefaultThemeContent(
    productTheme: ICardProductTheme,
  ): Array<ICardProductPage> | undefined {
    return productTheme.defaultContent
  }

  public static getDefaultPageOrientation() {
    return CardProductPageOrientation.PORTRAIT
  }

  public static createCardProductContentByThemeId({
    product,
    themeId,
    theme,
    isPopulatingData,
    populatedData,
    existingCardProduct,
    region = EulogiseRegion.AU,
  }: {
    product: EulogiseProduct
    themeId: string
    theme: ITheme
    isPopulatingData?: boolean
    populatedData?: ICardPopulatedTextData
    existingCardProduct?: ICardProductData
    region: EulogiseRegion
  }): ICardProductContent {
    const populatedTheme: ICardProductTheme | ISlideshowTheme =
      this.getCardProductThemeWithPopulatedData({
        product,
        themeId,
        theme,
        region,
        populatedData: isPopulatingData ? populatedData! : undefined,
        existingCardProduct,
      })!

    const pages = this.getDefaultThemeContent(
      populatedTheme as ICardProductTheme,
    )!
    const pageMargins: number[] =
      region === EulogiseRegion.USA
        ? this.getDefaultUSPageMargins(product)!
        : this.getDefaultAUPageMargins(product)!
    const productTheme = ThemeHelper.getProductThemeByProductType({
      theme,
      product,
      region,
    }) as ICardProductTheme

    const { width: pageContentWidth } =
      this.getDefaultPageContentWidthAndHeight({
        product,
        defaultThemeLayoutColumns: productTheme.defaultThemeLayoutColumns,
        region,
      })

    return {
      theme: themeId.toLowerCase(),
      pageSize: this.getDefaultPageSize({
        product,
        defaultThemeLayoutColumns: productTheme.defaultThemeLayoutColumns,
        region,
      }),
      pageOrientation: this.getDefaultPageOrientation(),
      pageMargins,
      pages: this.refinePages({ pages, productTheme, pageContentWidth }),
    }
  }

  public static styleDefToStyle(styleDef: any, options: any = {}) {
    const { font, margin, ...rest } = styleDef
    const style = {
      ...rest,
      fontFamily: font,
    }

    if (!options.excludeMargin) {
      const marginArray = typeof margin === 'number' ? [margin] : margin || [0]

      style.padding = `${marginArray[1] || marginArray[0]}px ${
        marginArray[2] || marginArray[0]
      }px ${marginArray[3] || marginArray[1] || marginArray[0]}px ${
        marginArray[0]
      }px`
    }

    return style
  }

  public static getBlockRenderMap(
    productTheme: ICardProductTheme,
    rowStyle: IRowStyle,
  ) {
    const paragraphsBlockRenderMap = Immutable.Map({
      'paragraph-one': {
        element: 'div',
      },
      'paragraph-two': {
        element: 'div',
      },
    })

    const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(
      paragraphsBlockRenderMap,
    )
    return extendedBlockRenderMap.map((item, key) => {
      const themeStyle = { ...productTheme.styles[key], ...rowStyle }
      if (themeStyle) {
        const style = this.styleDefToStyle(themeStyle, { excludeMargin: true })

        const Wrapper = ({ children }: any) => (
          <section style={style}>{children}</section>
        )

        // @ts-ignore
        item.wrapper = <Wrapper style={style} />
      }

      return item
    })
  }

  public static calculateBestFontSizeForTextBlock(props: {
    productTheme: ICardProductTheme
    row: ICardProductTextRow
    pageContentWidth: number
    newFontSize?: number
  }): number {
    // work out the best font size that fit the size of the block
    const { productTheme, row, newFontSize, pageContentWidth } = props

    // START: get current font size from data
    const blocks = row.data.content.blocks
    const blockType = blocks[0]?.type
    const rowStyle = row.data.rowStyle
    const dataFontSize = this.getTextItemFontSize({
      rowStyle,
      blockType,
      productTheme,
    })
    // END - get current font size from data

    const rowData = row.data as ICardProductTextRowData
    const editorState = EditorState.createWithContent(
      convertFromRaw(rowData?.content as unknown as RawDraftContentState),
    )
    const container = document.createElement('div')

    // Apply styles to position the container off-screen
    container.style.position = 'absolute'
    container.style.left = '-9999px'
    container.style.lineHeight = 'normal'
    // please ensure the following style are same as StyledContainer in TextItem
    container.classList.add('text-item-calculation-container')

    ReactDOM.render(
      // @ts-ignore
      <Editor
        onChange={() => {}}
        editorState={editorState}
        textAlignment={rowData?.alignment}
        blockRenderMap={this.getBlockRenderMap(productTheme, {
          ...rowData?.rowStyle!,
          // use new font size if available
          fontSize: newFontSize ?? dataFontSize,
        })}
        readOnly
      />,
      container,
    )

    // Append the container to the document body
    document.body.appendChild(container)
    const width = container.offsetWidth

    // Remove the container - since we have the width, we don't need it anymore
    document.body.removeChild(container)

    // if the current font size does not fit the block, reduce the font size
    if (width > pageContentWidth) {
      const newCheckFontSize = (newFontSize ? newFontSize : dataFontSize) - 1
      const minFontSize = 6
      if (newCheckFontSize <= minFontSize) {
        return minFontSize
      }
      return this.calculateBestFontSizeForTextBlock({
        productTheme,
        row,
        pageContentWidth,
        newFontSize: newCheckFontSize,
      })
    }
    return newFontSize ?? dataFontSize
  }

  public static refineTextRow(props: {
    productTheme: ICardProductTheme
    row: ICardProductTextRow
    pageContentWidth: number
  }): ICardProductTextRow {
    const { row } = props
    const adjustedFontSize = this.calculateBestFontSizeForTextBlock(props)
    return {
      ...row,
      data: {
        ...row.data,
        rowStyle: {
          ...row.data.rowStyle,
          // update with new font size
          fontSize: adjustedFontSize,
        },
      },
    }
  }

  public static refineRow(props: {
    row: ICardProductRow
    productTheme: ICardProductTheme
    pageContentWidth: number
  }): ICardProductRow {
    const { row, productTheme, pageContentWidth } = props
    // if it is a text row, work on the best font size for the text block
    if (row.type === CardProductContentItemType.TEXT) {
      return this.refineTextRow({ row, productTheme, pageContentWidth })
    }

    return row
  }

  public static refineSinglePage(props: {
    page: ICardProductPage
    productTheme: ICardProductTheme
    pageContentWidth: number
  }): ICardProductPage {
    const { page, productTheme, pageContentWidth } = props

    return {
      ...page,
      rows: page.rows.map((row: ICardProductRow) =>
        this.refineRow({ row, productTheme, pageContentWidth }),
      ),
    }
  }

  public static refinePages(props: {
    pages: Array<ICardProductPage>
    pageContentWidth: number
    productTheme: ICardProductTheme
  }): Array<ICardProductPage> {
    const { pages, productTheme, pageContentWidth } = props
    return pages.map((page: ICardProductPage) =>
      this.refineSinglePage({ page, productTheme, pageContentWidth }),
    )
  }

  public static getCardProductRow(
    cardProduct: ICardProductData,
    pageIndex: number,
    rowId: string,
  ): ICardProductRow {
    const page: ICardProductPage = cardProduct.content.pages[pageIndex]
    const rows = page.rows
    return rows.find((r: ICardProductRow) => r.id === rowId)!
  }

  public static getCardProductRowByRowId(
    pages: Array<ICardProductPage>,
    rowId: string,
  ): ICardProductRow | undefined {
    for (const page of pages) {
      for (const row of page.rows) {
        if (row.id === rowId) {
          return row
        }
      }
    }
    return
  }

  public static getTotalPageCursors(
    product: EulogiseProduct,
    totalPages: number,
    pageMode: CardProductPageMode = CardProductPageMode.NORMAL,
    isMobile: boolean = false,
    displayMode?: CardProductViewDisplayMode,
  ): number | undefined {
    if (displayMode === CardProductViewDisplayMode.THUMBNAIL) {
      if (product === EulogiseProduct.BOOKMARK) {
        if (totalPages > 1) {
          return 2
        }
      }
      return 1
    }
    // if predefined
    const cursors = this.getNoOfPageCursors(product)
    if (cursors) {
      return cursors
    }
    if (pageMode === CardProductPageMode.NORMAL) {
      if (isMobile || displayMode === CardProductViewDisplayMode.TEMPLATE) {
        return totalPages
      }
      const noOfDoublePages: number = (totalPages - 2) / 2
      const noOfFrontPages: number = 1
      const noOfBackPages: number = 1
      return noOfDoublePages + noOfFrontPages + noOfBackPages
    } else if (pageMode === CardProductPageMode.TWO_PAGES) {
      return Math.ceil(totalPages / 2)
    }
    return
  }

  // return 96dpi width and height
  public static getPdfPageViewport({
    product,
    bleed,
    type = 'MM',
  }: {
    product: EulogiseProduct | 'BOOKLET_LETTER' | 'SIDED_CARD_LETTER'
    bleed?: boolean
    type?: 'MM' | 'PX'
  }): {
    width: string
    height: string
  } {
    const scale = type === 'PX' ? MM_TO_PAGE_SIZE_SCALE : 1
    const [width, height] = VIEW_PORT_IN_MM[product]

    if (bleed) {
      const totalBleedPerPage = BLEED_IN_MM * 2 * 2
      return {
        width: ((width + totalBleedPerPage) * scale).toString(),
        height: ((height + totalBleedPerPage) * scale).toString(),
      }
    }

    return {
      width: (width * scale).toString(),
      height: (height * scale).toString(),
    }
  }

  public static getDefaultOverlayByProduct(
    product: EulogiseProduct,
  ): ICardProductOverlayUpdateOptions {
    const overlay = CARD_PRODUCTS_OVERLAYS[product]
    if (!overlay) {
      return CARD_PRODUCTS_OVERLAYS[EulogiseProduct.ALL]
    }
    return overlay
  }

  public static getOverlaySettingsFormFields({
    cardProduct,
    product,
  }: {
    cardProduct: ICardProductData
    product: EulogiseProduct
  }): ICardProductOverlayUpdateOptions {
    return (
      cardProduct.content.pageOverlay ??
      this.getDefaultOverlayByProduct(product)
    )
  }

  public static getBorderSettingsFormFields(
    cardProduct: ICardProductData,
  ): IBorderSettingsModalFormFields {
    const formFields: IBorderSettingsModalFormFields = {
      [CardProductBorderPageType.BACK_PAGE]: {
        borderStyle: CardProductBorderType.NONE,
      },
      [CardProductBorderPageType.FRONT_PAGE]: {
        borderStyle: CardProductBorderType.NONE,
      },
      [CardProductBorderPageType.MIDDLE_PAGES]: {
        borderStyle: CardProductBorderType.NONE,
      },
    }
    const contentPages = cardProduct.content.pages
    for (let i = 0; i < contentPages.length; i++) {
      const contentPage = contentPages[i]
      if (contentPage.border) {
        if (i === 0) {
          formFields[CardProductBorderPageType.FRONT_PAGE] = contentPage.border
        } else if (i === contentPages.length - 1) {
          formFields[CardProductBorderPageType.BACK_PAGE] = contentPage.border
        } else if (i !== 0 && i !== contentPages.length - 1) {
          formFields[CardProductBorderPageType.MIDDLE_PAGES] =
            contentPage.border
        }
      }
    }
    return formFields
  }

  public static getPageIndexesByPageCursor(
    product: EulogiseProduct,
    pageCursorIndex: number,
    totalPages: number,
    pageMode: CardProductPageMode = CardProductPageMode.NORMAL,
    isMobile?: boolean,
    displayMode?: CardProductViewDisplayMode,
  ): {
    leftPageIndex: number | undefined
    rightPageIndex: number | undefined
  } {
    let leftPageIndex
    let rightPageIndex
    const totalCursors: number = this.getTotalPageCursors(
      product,
      totalPages,
      pageMode,
      isMobile,
      displayMode,
    )!

    const newPageCursorIndex =
      pageCursorIndex >= totalCursors - 1 ? totalCursors - 1 : pageCursorIndex

    // For multiple pages e.g. booklet
    if (pageMode === CardProductPageMode.NORMAL) {
      if (displayMode === CardProductViewDisplayMode.TEMPLATE) {
        rightPageIndex = newPageCursorIndex
      } else if (
        isMobile &&
        (product === EulogiseProduct.BOOKLET ||
          product === EulogiseProduct.SIDED_CARD)
      ) {
        rightPageIndex = newPageCursorIndex
      } else {
        if (newPageCursorIndex === 0) {
          rightPageIndex = 0
        } else if (newPageCursorIndex === totalCursors - 1) {
          rightPageIndex = totalPages - 1
        } else {
          leftPageIndex = newPageCursorIndex * 2 - 1
          rightPageIndex = newPageCursorIndex * 2
        }
      }
    } else if (pageMode === CardProductPageMode.TWO_PAGES) {
      leftPageIndex = newPageCursorIndex * 2
      rightPageIndex = newPageCursorIndex * 2 + 1
    }
    return {
      leftPageIndex,
      rightPageIndex,
    }
  }

  public static isTwoPagesButActAsOnePage(product: EulogiseProduct): boolean {
    return EulogiseProduct.THANK_YOU_CARD !== product
  }

  public static getMaxPageContentWidth(cardProduct: ICardProductData) {
    const cardProductContent = cardProduct?.content
    const cardProductPageSize = cardProductContent?.pageSize
    const cardProductPageOrientation = cardProductContent?.pageOrientation
    const cardProductPageMargin =
      cardProductContent?.pageMargins as Array<number>
    const { pageWidth } = CardProductHelper.getPageWidthAndHeight(
      cardProductPageSize,
      cardProductPageOrientation,
    )
    return (
      pageWidth -
      cardProductPageMargin[0] -
      (cardProductPageMargin[2] || cardProductPageMargin[0])
    )
  }

  public static getBleedPageBackground({
    page,
    product,
  }: {
    page: ICardProductPage
    product: EulogiseProduct
  }) {
    if (!page?.background?.image) {
      return
    }
    const image = page.background.image
    const imageUrl = ImageHelper.getImageUrl(image)!

    if (product === EulogiseProduct.SIDED_CARD) {
      // e.g. Sailing_Watercolor_BOOKLET_FRONT_BOTH_SIDE_USA.jpg
      const region: EulogiseRegion = /_USA\.jpg$/.test(imageUrl)
        ? EulogiseRegion.USA
        : EulogiseRegion.AU
      return imageUrl?.replace(
        /(_BOTH_SIDE_USA\.jpg|_BOTH_SIDE\.jpg|_USA\.jpg|\.jpg)$/,
        `_BOTH_SIDE${region === EulogiseRegion.USA ? '_USA' : ''}_BLEED.jpg`,
      )
    }
    return imageUrl?.replace(/\.jpg$/, '_BLEED.jpg')
  }

  public static getFirstPage(props: {
    product: EulogiseProduct
    cardProduct: ICardProductData
    productTheme: ICardProductTheme
    bleed?: boolean
  }): ICardProductPage {
    return this.getPages(props)[0]
  }

  public static getFirstPageBackgroundImageUrl(props: {
    product: EulogiseProduct
    cardProduct: ICardProductData
    productTheme: ICardProductTheme
    bleed?: boolean
  }): string | undefined {
    const firstPageBackground = this.getFirstPageBackground(props)
    if (!firstPageBackground) {
      return
    }
    return ImageHelper.getImageUrl(firstPageBackground.image)
  }

  public static getFirstPageBackground(props: {
    product: EulogiseProduct
    cardProduct: ICardProductData
    productTheme: ICardProductTheme
    bleed?: boolean
  }) {
    return this.getFirstPage(props).background
  }

  public static getPages({
    product,
    cardProduct,
    productTheme,
    bleed,
  }: {
    product: EulogiseProduct
    cardProduct: ICardProductData
    productTheme: ICardProductTheme
    bleed?: boolean
  }): Array<ICardProductPage> {
    const cardProductContent = cardProduct?.content

    // TODO: move this on theme creation
    const isNoContent = cardProductContent.pages?.every(
      (page: ICardProductPage) => page.rows.length === 0,
    )

    if (isNoContent || isNoContent === undefined) {
      cardProductContent.pages = this.getDefaultThemeContent(productTheme)!
    }

    const cardProductPageSize = cardProductContent?.pageSize
    const cardProductPageOrientation = cardProductContent?.pageOrientation
    const cardProductPageMargin = cardProductContent?.pageMargins

    const pages = (cardProductContent.pages || []).map(
      (page: ICardProductPage) => {
        const { pageWidth, pageHeight } =
          CardProductHelper.getPageWidthAndHeight(
            cardProductPageSize,
            cardProductPageOrientation,
          )

        const contentHeight = page.rows.reduce(
          (total: number, row: ICardProductRow) =>
            total + this.getRowHeight(row),
          0,
        )
        const pm = cardProductPageMargin as Array<number>
        const contentBoundaries = {
          width: CardProductHelper.getMaxPageContentWidth(cardProduct),
          height: pageHeight - pm[1] - (pm[3] || pm[1]),
        }
        const pageStyle = this.getPageStyle({
          product,
          pageWidth,
          pageHeight,
          pageMargins: cardProductPageMargin,
          page,
          isBleed: bleed,
          isTwoPagesButActAsOnePage: this.isTwoPagesButActAsOnePage(product),
        })
        const bleedPageBackground = this.getBleedPageBackground({
          page,
          product,
        })

        return {
          ...page,
          pageWidth: pageWidth,
          pageHeight: pageHeight,
          contentHeight,
          contentBoundaries,
          pageStyle,
          bleedPageBackground,
        }
      },
    )

    return pages
  }

  public static getJustifyContentStyleByAlignment(
    alignment: AlignmentType,
  ): string | undefined {
    switch (alignment) {
      case AlignmentType.LEFT:
        return 'flex-start'
      case AlignmentType.CENTER:
        return 'center'
      case AlignmentType.RIGHT:
        return 'flex-end'
    }
    return
  }

  public static getNeedUpdatedCardProductsAfterEditing = (
    editedImage: IImageAsset,
    allActiveCardProducts: IAllActiveCardProducts,
  ): Array<EulogiseProduct> => {
    let needUpdatedCardProducts: Array<EulogiseProduct> = []

    let product: keyof IAllActiveCardProducts
    for (product in allActiveCardProducts) {
      const activeCardProduct: ICardProductData =
        allActiveCardProducts?.[product]!

      if (allActiveCardProducts[product]) {
        const activeCardProductContent = activeCardProduct?.content
        const { pages }: { pages: Array<ICardProductPage> } =
          activeCardProductContent
        const editedImageContent = editedImage?.content

        pages.forEach((page: ICardProductPage) => {
          page.rows.forEach((row: ICardProductRow) => {
            if (
              row.type === CardProductContentItemType.IMAGE &&
              !needUpdatedCardProducts.includes(product) &&
              row?.data?.filestackHandle === editedImageContent?.filestackHandle
            ) {
              needUpdatedCardProducts.push(product)
            } else if (
              row.type === CardProductContentItemType.COLUMNS &&
              !needUpdatedCardProducts.includes(product)
            ) {
              row.data?.items?.forEach((item: ICardProductImageRow) => {
                if (
                  item?.data?.filestackHandle ===
                  editedImageContent?.filestackHandle
                ) {
                  needUpdatedCardProducts.push(product)
                }
              })
            }
          })
        })
      }
    }
    return needUpdatedCardProducts
  }

  public static insertRowInPage = ({
    originalPageRows,
    insertRowIndex,
    insertNewRow,
  }: {
    originalPageRows: Array<ICardProductRow>
    insertRowIndex: number
    insertNewRow: ICardProductRow
  }): Array<ICardProductRow> => {
    const deepCopiedOriginalRows: Array<ICardProductRow> = JSON.parse(
      JSON.stringify(originalPageRows),
    )
    deepCopiedOriginalRows.splice(insertRowIndex, 0, insertNewRow)
    return deepCopiedOriginalRows
  }

  public static updateCardProductPageBackground({
    page,
    updatedPageImageData,
    cardProduct,
    pageIndex,
  }: {
    page: ICardProductPage
    updatedPageImageData: ICardProductBackground
    cardProduct: ICardProductData
    pageIndex: number
  }): Array<any> {
    const updatedPage = { ...page, background: updatedPageImageData }

    return [
      ...cardProduct.content.pages.slice(0, pageIndex),
      updatedPage,
      ...cardProduct.content.pages.slice(pageIndex + 1),
    ].filter(Boolean)
  }

  public static getHasPagesOverlayEnabled = (
    pages: Array<ICardProductPage>,
    leftPageIndex: number | undefined,
    rightPageIndex: number | undefined,
  ) => {
    return {
      isLeftPageOverlayed: this.getHasPageOverlayEnabledByIndex({
        pages,
        pageIndex: leftPageIndex,
      }),
      isRightPageOverlayed: this.getHasPageOverlayEnabledByIndex({
        pages,
        pageIndex: rightPageIndex,
      }),
    }
  }

  public static hasPageOverlay(page: ICardProductPage) {
    const background = page.background
    return background?.overlayEnabled === undefined
      ? !!background?.overlayColor &&
          !!background?.overlayMargin &&
          !!background?.overlayOpacity
      : background?.overlayEnabled
  }

  public static getHasPageOverlayEnabledByIndex = ({
    pages,
    pageIndex,
  }: {
    pages: Array<ICardProductPage>
    pageIndex?: number
  }) => {
    if (
      pageIndex !== undefined &&
      pages[pageIndex].background?.overlayEnabled !== undefined
    ) {
      return pages[pageIndex].background?.overlayEnabled
    }
    if (!pages || pageIndex === undefined) {
      return false
    }
    const background = pages?.[pageIndex]?.background
    return (
      !!background?.overlayColor &&
      !!background?.overlayMargin &&
      !!background?.overlayOpacity
    )
  }

  public static isCardProductBackgroundChangable = (
    productData: ICardProductData,
  ): boolean => {
    if (!productData) {
      return false
    }
    const isCompleted = productData?.status === MemorialVisualStatus.COMPLETE
    const isGenerated = productData?.fileStatus === ResourceFileStatus.GENERATED
    if (isCompleted || isGenerated) {
      return false
    }
    return true
  }

  public static isReadyForDownload(fileStatus: ResourceFileStatus) {
    return fileStatus === ResourceFileStatus.GENERATED
  }

  public static isCardProductThemeChangable = (
    productData: ICardProductData,
  ): boolean => {
    // never started, allow it to change
    if (!productData) {
      return true
    }
    const isCompleted = productData?.status === MemorialVisualStatus.COMPLETE
    const isGenerated = productData?.fileStatus === ResourceFileStatus.GENERATED
    if (isCompleted || isGenerated) {
      return false
    }
    return true
  }

  public static getCardProductsBackgroundChangableMap = (
    allActiveCardProductsData: IAllActiveCardProducts,
  ): Record<EulogiseCardProducts, boolean> => {
    let results: Record<EulogiseCardProducts, boolean> = {
      [EulogiseProduct.BOOKLET]: false,
      [EulogiseProduct.BOOKMARK]: false,
      [EulogiseProduct.SIDED_CARD]: false,
      [EulogiseProduct.THANK_YOU_CARD]: false,
      [EulogiseProduct.TV_WELCOME_SCREEN]: false,
    }

    if (!allActiveCardProductsData) {
      return results
    }

    for (const [product, productData] of Object?.entries(
      allActiveCardProductsData,
    )) {
      const isProductBGChangable =
        this.isCardProductBackgroundChangable(productData)
      results[product as EulogiseCardProducts] = isProductBGChangable
    }

    return results
  }

  public static get = (
    allActiveCardProductsData: IAllActiveCardProducts,
  ): Record<EulogiseCardProducts, boolean> => {
    let results: Record<EulogiseCardProducts, boolean> = {
      [EulogiseProduct.BOOKLET]: false,
      [EulogiseProduct.BOOKMARK]: false,
      [EulogiseProduct.SIDED_CARD]: false,
      [EulogiseProduct.THANK_YOU_CARD]: false,
      [EulogiseProduct.TV_WELCOME_SCREEN]: false,
    }

    if (!allActiveCardProductsData) {
      return results
    }

    for (const [product, productData] of Object?.entries(
      allActiveCardProductsData,
    )) {
      const isProductBGChangable =
        this.isCardProductBackgroundChangable(productData)
      results[product as EulogiseCardProducts] = isProductBGChangable
    }

    return results
  }

  public static getIsAtCardProductEditor = ({
    location,
  }: {
    location: Location
  }): boolean => {
    const path: string = location?.pathname!
    const [, firstPart, secondPart] = path.split('/')
    const simplifiedPath: string = `/${firstPart}/${secondPart}`

    const product: EulogiseProduct = {
      '/admin/slideshows': EulogiseProduct.SLIDESHOW,
      '/admin/booklets': EulogiseProduct.BOOKLET,
      '/admin/bookmarks': EulogiseProduct.BOOKMARK,
      '/admin/sidedCards': EulogiseProduct.SIDED_CARD,
      '/admin/thankYouCards': EulogiseProduct.THANK_YOU_CARD,
      '/admin/tvWelcomeScreens': EulogiseProduct.TV_WELCOME_SCREEN,
    }[simplifiedPath]!

    return [
      EulogiseProduct.BOOKLET,
      EulogiseProduct.BOOKMARK,
      EulogiseProduct.SIDED_CARD,
      EulogiseProduct.THANK_YOU_CARD,
      EulogiseProduct.TV_WELCOME_SCREEN,
    ].includes(product)
  }

  public static getAtWhichProductEditorPage = ({
    location,
  }: {
    location: Location
  }): EulogiseProduct => {
    const path: string = location?.pathname!
    const [, firstPart, secondPart] = path.split('/')
    const simplifiedPath: string = `/${firstPart}/${secondPart}`

    const product: EulogiseProduct = {
      '/admin/slideshows': EulogiseProduct.SLIDESHOW,
      '/admin/booklets': EulogiseProduct.BOOKLET,
      '/admin/bookmarks': EulogiseProduct.BOOKMARK,
      '/admin/sidedCards': EulogiseProduct.SIDED_CARD,
      '/admin/thankYouCards': EulogiseProduct.THANK_YOU_CARD,
      '/admin/tvWelcomeScreens': EulogiseProduct.TV_WELCOME_SCREEN,
      '/admin/image-library': EulogiseProduct.SLIDESHOW,
    }[simplifiedPath]!

    return product
  }

  public static getBookletEditorMagniferValueByDoubleClick(
    bookletMagniferSliderValue: number,
  ) {
    if (
      bookletMagniferSliderValue < 0 ||
      bookletMagniferSliderValue > 100 ||
      isNaN(bookletMagniferSliderValue)
    ) {
      return 0
    }
    if (bookletMagniferSliderValue >= 0 && bookletMagniferSliderValue < 25) {
      return 25
    }
    if (bookletMagniferSliderValue >= 25 && bookletMagniferSliderValue < 50) {
      return 50
    }
    if (bookletMagniferSliderValue >= 50 && bookletMagniferSliderValue <= 100) {
      return 100
    }
    return 0
  }
}
