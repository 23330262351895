import {
  EulogiseProduct,
  ICardProductState,
  IEulogiseState,
  ISlideshowState,
} from '@eulogise/core'

export class StateHelper {
  public static getProductStateByProduct(
    state: IEulogiseState,
    product: EulogiseProduct,
  ): ICardProductState | ISlideshowState {
    switch (product) {
      case EulogiseProduct.BOOKLET:
        return state.booklets
      case EulogiseProduct.BOOKMARK:
        return state.bookmarks
      case EulogiseProduct.SLIDESHOW:
        return state.slideshows
      case EulogiseProduct.SIDED_CARD:
        return state.sidedCards
      case EulogiseProduct.TV_WELCOME_SCREEN:
        return state.tvWelcomeScreens
      case EulogiseProduct.THANK_YOU_CARD:
        return state.thankYouCards
      default:
        throw new Error(
          `getProductStateByProduct: Invalid Product (${product})`,
        )
    }
  }
}
