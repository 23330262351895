import {
  EulogiseRegion,
  EulogiseCountry,
  ICase,
  INITIAL_DEFAULT_PRODUCTS,
  EulogiseProduct,
  IEulogiseProductAvailabilityStatus,
} from '@eulogise/core'

export class CaseHelper {
  public static getEnabledProducts(icase: ICase) {
    return icase?.enabledProducts ?? INITIAL_DEFAULT_PRODUCTS
  }

  public static getIsDisplayTwoColumns(icase: ICase) {
    const noOfEnabledProducts = this.getNoOfEnabledProducts(icase)
    return noOfEnabledProducts < 6 && noOfEnabledProducts % 2 === 0
  }

  public static getNoOfEnabledProducts(icase: ICase) {
    const enabledProducts = {
      ...this.getEnabledProducts(icase),

      // remove photobook from the list
      PHOTOBOOK: false,
    }
    return enabledProducts
      ? Object.values(enabledProducts).filter(Boolean).length
      : 0
  }

  public static isProductEnabled(
    enabledProducts: IEulogiseProductAvailabilityStatus,
    product: EulogiseProduct,
  ) {
    return enabledProducts[product]
  }

  public static isCaseProductEnabled(icase: ICase, product: EulogiseProduct) {
    const enabledProducts = this.getEnabledProducts(icase)
    return this.isProductEnabled(enabledProducts, product)
  }

  public static getRegionByCountry({
    country,
  }: {
    country: EulogiseCountry
  }): EulogiseRegion {
    const US_REGION_COUNTRIES = [
      EulogiseCountry.UNITED_STATES,
      EulogiseCountry.CANADA,
      EulogiseCountry.CHILE,
      EulogiseCountry.COLUMBIA,
      EulogiseCountry.COSTA_RICA,
      EulogiseCountry.MEXICO,
      EulogiseCountry.PANAMA,
      EulogiseCountry.GUATEMALA,
      EulogiseCountry.THE_DOMINICAN_REPUBLIC,
      EulogiseCountry.THE_PHILIPPINES,
      EulogiseCountry.REST_OF_THE_WOLRD,
    ]
    if (US_REGION_COUNTRIES.includes(country)) {
      return EulogiseRegion.USA
    }
    return EulogiseRegion.AU
  }

  public static getProductPriceByCountry({
    country,
  }: {
    country: EulogiseCountry
  }) {
    switch (country) {
      // Charge with GBP £49
      case EulogiseCountry.UNITED_KINGDOM:
        return [
          {
            sku: 'DIGITAL',
            price: 4900,
            description: 'Digital.',
          },
          {
            sku: 'PRINT-50-SILK',
            price: -1,
            description: 'Printed. 50 Units. Silk Paper.',
          },
          {
            sku: 'PRINT-50-MATTE',
            price: -1,
            description: 'Printed. 50 Units. Matte Paper.',
          },
          {
            sku: 'PRINT-50-LINEN',
            price: -1,
            description: 'Printed. 50 Units. Linen Paper.',
          },
        ]
      // Charge with EURO €54
      case EulogiseCountry.EUROPEAN_UNION:
        return [
          {
            sku: 'DIGITAL',
            price: 5400,
            description: 'Digital.',
          },
          {
            sku: 'PRINT-50-SILK',
            price: -1,
            description: 'Printed. 50 Units. Silk Paper.',
          },
          {
            sku: 'PRINT-50-MATTE',
            price: -1,
            description: 'Printed. 50 Units. Matte Paper.',
          },
          {
            sku: 'PRINT-50-LINEN',
            price: -1,
            description: 'Printed. 50 Units. Linen Paper.',
          },
        ]
      // Charge with CAD $79
      case EulogiseCountry.CANADA:
        return [
          {
            sku: 'DIGITAL',
            price: 7900,
            description: 'Digital.',
          },
          {
            sku: 'PRINT-50-SILK',
            price: -1,
            description: 'Printed. 50 Units. Silk Paper.',
          },
          {
            sku: 'PRINT-50-MATTE',
            price: -1,
            description: 'Printed. 50 Units. Matte Paper.',
          },
          {
            sku: 'PRINT-50-LINEN',
            price: -1,
            description: 'Printed. 50 Units. Linen Paper.',
          },
        ]
      // Charge with NZD $105
      case EulogiseCountry.NEW_ZEALAND:
        return [
          {
            sku: 'DIGITAL',
            price: 10500,
            description: 'Digital.',
          },
          {
            sku: 'PRINT-50-SILK',
            price: -1,
            description: 'Printed. 50 Units. Silk Paper.',
          },
          {
            sku: 'PRINT-50-MATTE',
            price: -1,
            description: 'Printed. 50 Units. Matte Paper.',
          },
          {
            sku: 'PRINT-50-LINEN',
            price: -1,
            description: 'Printed. 50 Units. Linen Paper.',
          },
        ]
      // Charge with USD:
      case EulogiseCountry.UNITED_STATES:
      case EulogiseCountry.CHILE:
      case EulogiseCountry.COLUMBIA:
      case EulogiseCountry.COSTA_RICA:
      case EulogiseCountry.MEXICO:
      case EulogiseCountry.PANAMA:
      case EulogiseCountry.GUATEMALA:
      case EulogiseCountry.THE_DOMINICAN_REPUBLIC:
      case EulogiseCountry.THE_PHILIPPINES:
      case EulogiseCountry.REST_OF_THE_WOLRD:
        return [
          {
            sku: 'DIGITAL',
            price: 6900,
            description: 'Digital.',
          },
          {
            sku: 'PRINT-50-SILK',
            price: -1,
            description: 'Printed. 50 Units. Silk Paper.',
          },
          {
            sku: 'PRINT-50-MATTE',
            price: -1,
            description: 'Printed. 50 Units. Matte Paper.',
          },
          {
            sku: 'PRINT-50-LINEN',
            price: -1,
            description: 'Printed. 50 Units. Linen Paper.',
          },
        ]
      default:
        // Charge with AUD
        return [
          {
            sku: 'DIGITAL',
            price: 9900,
            description: 'Digital.',
          },
          {
            sku: 'PRINT-50-SILK',
            price: 21700,
            description: 'Printed. 50 Units. Silk Paper.',
          },
          {
            sku: 'PRINT-50-MATTE',
            price: 27200,
            description: 'Printed. 50 Units. Matte Paper.',
          },
          {
            sku: 'PRINT-50-LINEN',
            price: 27200,
            description: 'Printed. 50 Units. Linen Paper.',
          },
        ]
    }
  }
}
