import {
  ICheckoutsState,
  EulogiseCheckoutsOptions,
  ICheckoutsStateAction,
  CheckoutsActionTypes,
  EulogiseCardProducts,
  EulogiseShippingPaperTypes,
} from '@eulogise/core'

const initialState: ICheckoutsState = {
  paymentOption: EulogiseCheckoutsOptions.DIGITAL_DOWNLOAD_ONLY,
  shippingDetails: {
    [EulogiseCardProducts.BOOKLET]: {
      isShipping: true,
      productPageAmount: 0,
      paperType: EulogiseShippingPaperTypes.MATTE_CARD,
      copiesAmount: 150,
    },
    [EulogiseCardProducts.THANK_YOU_CARD]: {
      isShipping: false,
      productPageAmount: 0,
      paperType: EulogiseShippingPaperTypes.MATTE_CARD,
      copiesAmount: 150,
    },
    [EulogiseCardProducts.BOOKMARK]: {
      isShipping: false,
      productPageAmount: 0,
      paperType: EulogiseShippingPaperTypes.MATTE_CARD,
      copiesAmount: 150,
    },
    [EulogiseCardProducts.SIDED_CARD]: {
      isShipping: false,
      productPageAmount: 0,
      paperType: EulogiseShippingPaperTypes.MATTE_CARD,
      copiesAmount: 150,
    },
  },
  isComingFromPaymentPage: false,
  willOpenThemeDrawer: false,
  isSlideshowGenerating: false,
}

export const CheckoutsReducer = (
  state: ICheckoutsState = initialState,
  action: ICheckoutsStateAction,
): ICheckoutsState => {
  switch (action.type) {
    case CheckoutsActionTypes.UPDATE_PAYMENT_OPTION: {
      const { paymentOption } = action.payload!
      return {
        ...state,
        paymentOption: paymentOption!,
      }
    }
    case CheckoutsActionTypes.UPDATE_PRODUCT_PAPER_TYPE: {
      const { product, paperType } = action.payload!
      if (product && paperType) {
        return {
          ...state,
          shippingDetails: {
            ...state.shippingDetails,
            [product]: {
              ...state.shippingDetails[product],
              paperType,
            },
          },
        }
      }
    }
    case CheckoutsActionTypes.UPDATE_PRODUCT_COPIES_AMOUNT: {
      const { product, copiesAmount } = action.payload!
      if (product && copiesAmount) {
        return {
          ...state,
          shippingDetails: {
            ...state.shippingDetails,
            [product]: {
              ...state.shippingDetails[product],
              copiesAmount,
            },
          },
        }
      }
    }
    case CheckoutsActionTypes.UPDATE_IS_COMING_FROM_PAYMENT_PAGE: {
      const { isComingFromPaymentPage } = action.payload!
      return {
        ...state,
        isComingFromPaymentPage: isComingFromPaymentPage!,
      }
    }
    case CheckoutsActionTypes.UPDATE_WILL_OPEN_THEME_DRAWER: {
      const { willOpenThemeDrawer } = action.payload!
      return {
        ...state,
        willOpenThemeDrawer: willOpenThemeDrawer!,
      }
    }
    case CheckoutsActionTypes.RESTORE_INITIAL_STATE: {
      return initialState
    }
    case CheckoutsActionTypes.UPDATE_IS_SLIDESHOW_GENERATING: {
      const { isSlideshowGenerating } = action.payload!
      return {
        ...state,
        isSlideshowGenerating: isSlideshowGenerating!,
      }
    }
    default:
      return state
  }
}

export const CheckoutsInitialState = initialState
