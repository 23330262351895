import axios from 'axios'

export class DownloadHelper {
  public static downloadAs = async (url: string, filename: string) => {
    const response = await axios({
      url,
      method: 'GET',
      responseType: 'blob',
    })
    const newUrl = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = newUrl
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
