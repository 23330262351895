import { all } from 'redux-saga/effects'
import { AdminSagas } from './AdminState/sagas'
import { AssetSagas } from './AssetState/sagas'
import { AuthSagas } from './AuthState/sagas'
import { BackgroundImageSagas } from './BackgroundImageState/sagas'
import { CardProductSagas } from './CardProduct/sagas'
import { CaseSagas } from './CaseState/sagas'
import { ClientSagas } from './ClientState/sagas'
import { InviteSagas } from './InviteState/sagas'
import { InvoiceSagas } from './InvoiceState/sagas'
import { ModalSagas } from './ModalState/sagas'
import { SlideshowSagas } from './SlideshowState/sagas'
import { ThemeSagas } from './ThemeState/sagas'

// Create the root saga to run multiple sagas
export function* EulogiseRootSaga() {
  yield all([
    ...AdminSagas,
    ...AssetSagas,
    ...AuthSagas,
    ...BackgroundImageSagas,
    ...CardProductSagas,
    ...CaseSagas,
    ...ClientSagas,
    ...InviteSagas,
    ...InvoiceSagas,
    ...ModalSagas,
    ...SlideshowSagas,
    ...ThemeSagas,
  ])
}
