import { put, takeEvery } from 'redux-saga/effects'
import {
  AssetActionTypes,
  AssetType,
  EulogiseResource,
  IAudioAssetCategory,
  IAudioAssetContent,
  IImageAsset,
} from '@eulogise/core'
import axios from 'axios'
import RequestHelper from '../../helpers/RequestHelper'
import {
  FetchAssetsByCaseIdAction,
  fetchAudioAssetsByCaseId,
  FetchBrandsByClientIdAction,
  RemoveAssetAction,
  saveAudioFromFileStack,
  SaveAudioFromFileStackAction,
  SaveAudiosFromFilestackAction,
  SaveEditedAudioBufferAction,
  SaveBrandFromFilestackAction,
  SaveImageAction,
  saveImageFromFilestack,
  SaveImageFromFilestackAction,
  SaveImagesFromFilestackAction,
  SaveImageWithInsertIndexAction,
  UpdateImagesOrdersAction,
  RemoveImageBackgroundAction,
} from './actions'
import { AssetHelper } from '@eulogise/helpers'
import { Notification } from '@eulogise/client-components'

function* handleFetchAssetsByCaseId(action: FetchAssetsByCaseIdAction) {
  try {
    const {
      payload: { caseId, assetType, sortBy, customisedImagesOrderIds },
    } = action

    if (!caseId) {
      throw new Error('fetchAssetsByCaseId: caseId must be provided')
    }
    const { data } = yield RequestHelper.findResourceRequest(
      EulogiseResource.ASSET,
      caseId,
      { type: assetType },
    )

    yield put({
      type: AssetActionTypes.FETCH_ASSETS_BY_CASE_ID_SUCCESS,
      payload: {
        // @ts-ignore
        items: data?.items,
        assetType,
        sortBy,
        customisedImagesOrderIds,
      },
    })
  } catch (ex) {
    yield put({
      type: AssetActionTypes.FETCH_ASSETS_BY_CASE_ID_FAILED,
    })
  }
}

function* handleFetchBrandsByClientId(action: FetchBrandsByClientIdAction) {
  try {
    const {
      payload: { clientId },
    } = action

    if (!clientId) {
      throw new Error('handleFetchBrandsByClientId: clientId must be provided')
    }
    const caseId = '*'
    const { data } = yield RequestHelper.findResourceRequest(
      EulogiseResource.ASSET,
      caseId,
      {
        type: AssetType.BRAND,
        client: clientId,
        shouldSkipAccessControlCheck: true,
      },
    )

    yield put({
      type: AssetActionTypes.FETCH_BRANDS_BY_CLIENT_ID_SUCCESS,
      payload: {
        // @ts-ignore
        items: data?.items,
      },
    })
  } catch (ex) {
    yield put({
      type: AssetActionTypes.FETCH_BRANDS_BY_CLIENT_ID_FAILED,
    })
  }
}

function* handleSaveAudioFromFilestack(action: SaveAudioFromFileStackAction) {
  yield put({
    type: AssetActionTypes.SAVE_ASSET,
    payload: {
      type: AssetType.AUDIO,
    },
  })

  let tags: any
  const {
    payload: { caseId, file, onSuccess },
  } = action

  try {
    // @ts-ignore
    tags = yield AssetHelper.getAudioTag(file.originalFile)
    if (onSuccess) {
      onSuccess
    }
  } catch (ex) {
    // Some audio do not have tags, we could not retrieve from media tags header.
    tags = {
      title: file?.originalFile?.name || file?.filename,
      artist: 'No Aritst',
    }
    if (!tags.title) {
      // throw error when no audio filename
      yield put({
        type: AssetActionTypes.SAVE_ASSET_FAILED,
        payload: {
          type: AssetType.AUDIO,
          ex,
        },
      })
    }
  }

  const duration: number = yield AssetHelper.getAudioDuration(file.url)

  try {
    const {
      data: {
        item: { content: audioContent },
      },
    } = yield RequestHelper.saveResourceRequest(EulogiseResource.ASSET, {
      case: caseId,
      content: {
        title: tags.title,
        artist: tags.artist,
        filename: file.key.split('/').pop()!,
        filepath: file.key,
        filestackHandle: file.handle,
        duration: Math.round(duration * 1000),
        category: IAudioAssetCategory.UPLOADED,
      },
      type: AssetType.AUDIO,
    })

    yield put({
      type: AssetActionTypes.SAVE_ASSET_SUCCESS,
      payload: {
        type: AssetType.AUDIO,
      },
    })
    onSuccess(audioContent)
    yield put(fetchAudioAssetsByCaseId(caseId))
  } catch (ex) {
    console.log('Exception', ex)
    yield put({
      type: AssetActionTypes.SAVE_ASSET_FAILED,
      payload: {
        type: AssetType.AUDIO,
        ex,
      },
    })
  }
}

function* handleSaveEditedAudioBuffer(action: SaveEditedAudioBufferAction) {
  try {
    const {
      payload: {
        key,
        caseId,
        editedAudioFromFileStack,
        fileName,
        previousEditedAudioId,
        onSuccess,
      },
    } = action
    // generate S3 pre-signed url to upload edited audio
    const { data } = yield RequestHelper.generatePreSignedUrlRequest(
      EulogiseResource.ASSET,
      {
        key,
      },
    )

    const preSignedUrl: string = data?.item?.preSignedUrl
    yield axios.put(preSignedUrl, editedAudioFromFileStack)

    // Save records in the backend
    const duration: number = yield AssetHelper.getAudioDuration(
      editedAudioFromFileStack.url,
    )

    const {
      data: {
        item: { content: audioContent },
      },
    } = yield RequestHelper.saveResourceRequest(EulogiseResource.ASSET, {
      id: previousEditedAudioId,
      case: caseId,
      content: {
        title: fileName,
        artist: '',
        filename: editedAudioFromFileStack.key.split('/').pop()!,
        filepath: editedAudioFromFileStack.key,
        filestackHandle: editedAudioFromFileStack.handle,
        duration: Math.round(duration * 1000),
        category: IAudioAssetCategory.UPLOADED,
      },
      type: AssetType.AUDIO,
    })

    if (onSuccess) {
      onSuccess(audioContent)
    }

    yield put(fetchAudioAssetsByCaseId(caseId))
    yield put({
      type: AssetActionTypes.SAVE_ASSET_SUCCESS,
      payload: {
        type: AssetType.AUDIO,
      },
    })
  } catch (ex) {
    yield put({
      type: AssetActionTypes.SAVE_ASSET_FAILED,
      payload: {
        type: AssetType.AUDIO,
        ex,
      },
    })
  }
}

function* handleSaveAudiosFromFilestack(action: SaveAudiosFromFilestackAction) {
  const {
    payload: { caseId, files, onSuccess },
  } = action
  const noOfFiles = files.length
  let saveItems: Array<IAudioAssetContent> = []
  for (const file of files) {
    yield put(
      saveAudioFromFileStack({
        caseId,
        file,
        onSuccess: (audioAssetContent) => {
          saveItems.push(audioAssetContent)
          if (saveItems.length === noOfFiles) {
            onSuccess(saveItems)
          }
        },
      }),
    )
  }
}

function* handleSaveImage(action: SaveImageAction) {
  yield put({
    type: AssetActionTypes.SAVE_ASSET,
    payload: {
      type: AssetType.IMAGE,
    },
  })
  try {
    const {
      payload: { file, onSuccess },
    } = action

    console.log('handleSaveImage file', file)
    const {
      data: { item: image },
    }: { data: { item: IImageAsset } } =
      yield RequestHelper.saveResourceRequest(EulogiseResource.ASSET, file)

    yield put({
      type: AssetActionTypes.SAVE_ASSET_SUCCESS,
      payload: {
        type: AssetType.IMAGE,
        image,
      },
    })

    if (onSuccess) {
      onSuccess()
    } else {
      Notification.success('Image saved')
    }
  } catch (ex) {
    yield put({
      type: AssetActionTypes.SAVE_ASSET_FAILED,
      payload: {
        type: AssetType.IMAGE,
        ex,
      },
    })
    Notification.error('Failed to upload new image')
  }
}

function* handleSaveImageWithInsertIndex(
  action: SaveImageWithInsertIndexAction,
) {
  yield put({
    type: AssetActionTypes.SAVE_ASSET,
    payload: {
      type: AssetType.IMAGE,
    },
  })

  try {
    const {
      payload: { newImageInsertIndex, file, onSaveNewCustomisedImageOrderIds },
    } = action

    const {
      data: { item: image },
    }: { data: { item: IImageAsset } } =
      yield RequestHelper.saveResourceRequest(EulogiseResource.ASSET, file)

    if (newImageInsertIndex && newImageInsertIndex >= 0) {
      yield put({
        type: AssetActionTypes.SAVE_ASSET_SUCCESS_WITH_INSERT_INDEX,
        payload: {
          type: AssetType.IMAGE,
          image,
          insertIndex: newImageInsertIndex,
        },
      })
    }
    if (
      onSaveNewCustomisedImageOrderIds &&
      newImageInsertIndex &&
      newImageInsertIndex >= 0
    ) {
      onSaveNewCustomisedImageOrderIds(image?.id, newImageInsertIndex)
    }
    yield put({
      type: AssetActionTypes.UPLOAD_EDITED_IMAGE_SUCCESS,
      payload: {},
    })
  } catch (ex) {
    yield put({
      type: AssetActionTypes.SAVE_ASSET_FAILED,
      payload: {
        type: AssetType.IMAGE,
        ex,
      },
    })
    yield put({
      type: AssetActionTypes.UPLOAD_EDITED_IMAGE_FAILED,
      payload: {},
    })
    Notification.error('Failed to upload new image with an insert index')
  }
}

function* handleSaveImageFromFilestack(action: SaveImageFromFilestackAction) {
  console.log('saveImageFromFilestack')
  yield put({
    type: AssetActionTypes.SAVE_ASSET,
    payload: {
      type: AssetType.IMAGE,
    },
  })

  const {
    payload: { caseId, file, complete },
  } = action

  try {
    const fileName = file.key.split('/').pop()
    if (!fileName) {
      throw new Error(
        'handleSaveImageFromFilestack: fileName must not be empty',
      )
    }
    const {
      data: { item: image },
    }: { data: { item: IImageAsset } } =
      yield RequestHelper.saveResourceRequest(EulogiseResource.ASSET, {
        case: caseId,
        content: {
          filename: fileName,
          filepath: file.key,
          filestackHandle: file.handle,
        },
        type: AssetType.IMAGE,
      })

    yield put({
      type: AssetActionTypes.SAVE_ASSET_SUCCESS,
      payload: {
        type: AssetType.IMAGE,
        image,
      },
    })
  } catch (ex) {
    yield put({
      type: AssetActionTypes.SAVE_ASSET_FAILED,
      payload: {
        type: AssetType.IMAGE,
        ex,
      },
    })
  }
  if (complete) {
    complete()
  }
}

function* handleSaveBrandFromFilestack(action: SaveBrandFromFilestackAction) {
  console.log('saveBrandFromFilestack')
  yield put({
    type: AssetActionTypes.SAVE_ASSET,
    payload: {
      type: AssetType.BRAND,
    },
  })

  const {
    payload: { client, file, complete },
  } = action

  if (!client) {
    throw new Error('saveBrandFromFilestack: client must be provided')
  }

  try {
    const fileName = file.key.split('/').pop()
    const filestackHandle = file?.url?.replace(
      `${process.env.GATSBY_FILESTACK_CDN}/`,
      '',
    )
    if (!fileName) {
      throw new Error(
        'handleSaveBrandFromFilestack: fileName must not be empty',
      )
    }
    const {
      data: { item: brand },
    }: { data: { item: IImageAsset } } =
      yield RequestHelper.saveResourceRequest(EulogiseResource.ASSET, {
        case: '*',
        client,
        content: {
          filename: fileName,
          filepath: file.key,
          filestackHandle,
        },
        type: AssetType.BRAND,
      })

    yield put({
      type: AssetActionTypes.SAVE_ASSET_SUCCESS,
      payload: {
        type: AssetType.BRAND,
        brand,
      },
    })
  } catch (ex) {
    yield put({
      type: AssetActionTypes.SAVE_ASSET_FAILED,
      payload: {
        type: AssetType.BRAND,
        ex,
      },
    })
  }
  if (complete) {
    complete()
  }
}

function* handleSaveImagesFromFilestack(action: SaveImagesFromFilestackAction) {
  const {
    payload: { caseId, complete, files },
  } = action

  for (const file of files) {
    yield put(saveImageFromFilestack({ caseId, file }))
  }
  if (complete) {
    complete()
  }
}

function* handleRemoveAsset(action: RemoveAssetAction) {
  const {
    payload: { assetId, onSuccess, assetType },
  } = action
  try {
    if (!assetId) {
      throw Error('Remove asset failed, no assetId provided.')
    }
    yield RequestHelper.removeResourceRequest(EulogiseResource.ASSET, assetId)

    yield put({
      type: AssetActionTypes.REMOVE_ASSET_SUCCESS,
      payload: {
        assetId,
      },
    })
    if (onSuccess) {
      onSuccess()
    } else {
      // Make the first letter uppercase
      const msg = `${
        assetType.charAt(0).toUpperCase() + assetType.slice(1)
      } removed.`
      Notification.success(msg)
    }
  } catch (ex) {
    yield put({
      type: AssetActionTypes.REMOVE_ASSET_FAILED,
      payload: {
        ex,
      },
    })
    Notification.error(`Failed to remove the ${assetType}.`)
  }
}

function* handleRemoveImageBackground(action: RemoveImageBackgroundAction) {
  const {
    payload: { assetId, onSuccess, mode },
  } = action
  try {
    if (!assetId) {
      throw Error('Remove asset failed, no assetId provided.')
    }
    Notification.info('Image background is being removed')
    yield RequestHelper.removeImageBackgroundResource(
      EulogiseResource.ASSET,
      assetId,
      mode,
    )

    const res: unknown = yield put({
      type: AssetActionTypes.REMOVE_IMAGE_BACKGROUND_SUCCESS,
      payload: {
        assetId,
      },
    })
    console.log('handleRemoveImageBackground res', res)
    if (onSuccess) {
      onSuccess()
    } else {
      Notification.success('Image background removed.')
    }
  } catch (ex) {
    yield put({
      type: AssetActionTypes.REMOVE_IMAGE_BACKGROUND_FAILED,
      payload: {
        ex,
      },
    })
    Notification.error(`Failed to remove image background.`)
  }
}

function* handleUpdateImagesOrder(action: UpdateImagesOrdersAction) {
  const {
    payload: { complete },
  } = action
  if (complete) {
    complete()
  }
}

/* Watchers */
function* watchers() {
  yield takeEvery(
    AssetActionTypes.FETCH_ASSETS_BY_CASE_ID,
    handleFetchAssetsByCaseId,
  )
  yield takeEvery(
    AssetActionTypes.FETCH_BRANDS_BY_CLIENT_ID,
    handleFetchBrandsByClientId,
  )
  yield takeEvery(
    AssetActionTypes.SAVE_AUDIO_FROM_FILESTACK,
    handleSaveAudioFromFilestack,
  )
  yield takeEvery(
    AssetActionTypes.SAVE_EDITED_AUDIO_BUFFER,
    handleSaveEditedAudioBuffer,
  )
  yield takeEvery(
    AssetActionTypes.SAVE_AUDIOS_FROM_FILESTACK,
    handleSaveAudiosFromFilestack,
  )
  yield takeEvery(AssetActionTypes.SAVE_IMAGE, handleSaveImage)
  yield takeEvery(
    AssetActionTypes.SAVE_IMAGE_WITH_INSERT_INDEX,
    handleSaveImageWithInsertIndex,
  )
  yield takeEvery(
    AssetActionTypes.SAVE_IMAGE_FROM_FILESTACK,
    handleSaveImageFromFilestack,
  )
  yield takeEvery(
    AssetActionTypes.SAVE_BRAND_FROM_FILESTACK,
    handleSaveBrandFromFilestack,
  )
  yield takeEvery(
    AssetActionTypes.SAVE_IMAGES_FROM_FILESTACK,
    handleSaveImagesFromFilestack,
  )
  yield takeEvery(AssetActionTypes.UPDATE_IMAGES_ORDER, handleUpdateImagesOrder)
  yield takeEvery(AssetActionTypes.REMOVE_ASSET, handleRemoveAsset)
  yield takeEvery(
    AssetActionTypes.REMOVE_IMAGE_BACKGROUND,
    handleRemoveImageBackground,
  )
}

export const AssetSagas = [watchers()]
